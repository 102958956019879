import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./faxPreview.css";
import Swal from "sweetalert2";
import { setLoader } from "../Redux/Actions/GeneralActions";
import html2canvas from "html2canvas";
import { useMediaQuery } from "react-responsive";

const PreView = ({ bookingDetail, prescription, setMainOpen }) => {
  const token = useSelector((state) => state.auth.doctorToken);
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const HandlePDFFile = () => {
    dispatch(setLoader(true));

    try {
      var divToPrint = document.getElementById(prescription?._id);

      html2canvas(divToPrint, { useCORS: true }).then((canvas) => {
        // Generate a unique ID for the link
        const linkId = `downloadLink_${new Date(
          prescription?.createdAt
        )?.getTime?.()}`;

        // Convert canvas to data URL
        const imageDataUrl = canvas.toDataURL();

        // Create a link element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.id = linkId;
        downloadLink.href = imageDataUrl;
        downloadLink.download = `prescription_${
          bookingDetail?.personal_info?.user_id?.first_name
        }_${bookingDetail?.personal_info?.user_id?.last_name}_${new Date(
          prescription?.createdAt
        )?.toLocaleDateString?.()}.png`;

        // Append the link to the document and trigger the download
        document.body.appendChild(downloadLink);
        dispatch(setLoader(false));

        downloadLink.click();

        // Remove the link and canvas from the document after use
        document.body.removeChild(downloadLink);
        canvas.remove();
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const flex = (direction, justify, align, gap) => ({
    display: "flex",
    flexDirection: direction || "row",
    justifyContent: justify || "stretch",
    alignItems: align || "stretch",
    gap: gap || "0px",
  });

  const border = {
    border: "1px solid black",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: isMobile ? "70px 0px 0px 0px" : "70px 30px 0px 30px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            padding: "10px",
            gap: "5px",
            width: "890px",
          }}
          id={prescription?._id}
        >
          {/* MAIN CONTAINER */}
          <div
            style={{
              ...border,
              padding: "10px",
              color: "black",
              flexGrow: 1,
              ...flex("column", "", "center"),
            }}
          >
            {/* HEADER */}
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <h3 style={{ textAlign: "center", marginBottom: "5px" }}>
                処　　方　　箋
              </h3>
              <h6 style={{ textAlign: "center" }}>
                （この処方箋は、どの保険薬局でも有効です。）
              </h6>
            </div>

            {/* UNDER HEADER */}
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "140px",
                    ...flex("row", "center", "center"),
                  }}
                >
                  公費負担者番号
                </div>{" "}
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>{" "}
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "180px",
                    textAlign: "center",
                    ...flex("row", "center", "center"),
                  }}
                >
                  保険者番号
                </div>{" "}
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "140px",
                    ...flex("row", "center", "center"),
                  }}
                >
                  公費負担医療 の受給者番号
                </div>{" "}
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "calc(calc(100% - 320px)/6)",
                  }}
                ></div>
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    width: "180px",
                    ...flex("row", "center", "center"),
                  }}
                >
                  被保険者証・被保険 者手帳の記号・番号
                </div>{" "}
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    textAlign: "end",
                    ...flex("row", "flex-end", "center"),
                    width: "calc(calc(100% - 320px)/2)",
                  }}
                >
                  ・　　（枝番）
                </div>{" "}
              </div>
            </div>

            {/* MAIN DETAILS */}
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              {/* LEFT DETAILS */}

              <div style={{ width: "50%", display: "flex", height: "100%" }}>
                <div
                  style={{
                    width: "30px",
                    writingMode: "vertical-lr",
                    textOrientation: "upright",
                    ...border,
                    ...flex("row", "center", "center"),
                  }}
                >
                  患 者
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      width: "120px",
                      ...flex("row", "center", "center"),
                      height: "calc(100% / 3)",
                    }}
                  >
                    氏　名
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% - 120px)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "calc(100% / 3)",
                    }}
                  >
                    {bookingDetail?.personal_info?.user_id?.first_name +
                      " " +
                      bookingDetail?.personal_info?.user_id?.last_name}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "120px",
                      height: "calc(100% / 3)",
                    }}
                  >
                    生年月日
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(calc(100% - 120px)/2)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "calc(100% / 3)",
                    }}
                  >
                    {bookingDetail?.personal_info?.user_id?.dob}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(calc(100% - 120px)/2)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "calc(100% / 3)",
                    }}
                  >
                    {bookingDetail?.personal_info?.gender}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "120px",
                      height: "calc(100% / 3)",
                    }}
                  >
                    区　分
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(calc(100% - 120px)/2)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "calc(100% / 3)",
                    }}
                  >
                    被保険者
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(calc(100% - 120px)/2)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      height: "calc(100% / 3)",
                    }}
                  >
                    被扶養者
                  </div>
                </div>
              </div>

              {/* RIGHT DETAILS */}

              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexWrap: "wrap",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    ...flex("row", "stretch", "center", "10px"),
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    保険医療機関の
                  </h4>
                  <h5></h5>
                </div>
                <div
                  style={{
                    ...flex("row", "stretch", "center", "10px"),
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    所在地及び名称
                  </h4>
                  <h5
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    東京都港区赤坂1-7-4 No.1 ニイクラビル3F
                    アメリカンクリニック東京
                  </h5>
                </div>{" "}
                <div
                  style={{
                    ...flex("row", "stretch", "center", "10px"),
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    診療所の電話番号
                  </h4>
                  <h5
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    +81-3-6441-0969
                  </h5>
                </div>{" "}
                <div
                  style={{
                    ...flex("row", "stretch", "center", "10px"),
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    保 険 医 氏 名
                  </h4>
                  <h5
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    {bookingDetail?.doctor_id?.first_name +
                      " " +
                      bookingDetail?.doctor_id?.last_name}
                  </h5>
                </div>{" "}
                <div
                  style={{
                    ...flex("row", "stretch", "center", "10px"),
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <h4
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    医師の電話番号
                  </h4>
                  <h5
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "inherit",
                    }}
                  >
                    {bookingDetail?.doctor_id?.phone_number}
                  </h5>{" "}
                  {bookingDetail?.doctor_id?.certificate && (
                    <img
                      src={
                        bookingDetail?.doctor_id?.certificate + "?ot-fromddf-df"
                      }
                      crossorigin="anonymous"
                      alt="doctor certificate"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50px",
                        position: "absolute",
                        right: "0px",
                        bottom: "0px",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      width: "calc(100% / 6)",
                      fontSize: "10px",
                      ...flex("row", "center", "center"),
                    }}
                  >
                    都道府県番号
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% / 6)",
                      fontSize: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% / 6)",
                      fontSize: "10px",
                    }}
                  >
                    点数表 番号
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% / 6)",
                      fontSize: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% / 6)",
                      fontSize: "10px",
                    }}
                  >
                    医療機関 コード
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      ...border,
                      ...flex("row", "center", "center"),
                      width: "calc(100% / 6)",
                    }}
                  ></div>
                </div>{" "}
              </div>
            </div>

            {/* MEDICINES TOP */}

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  padding: "10px",
                  ...border,
                  width: "148px",
                  ...flex("row", "center", "center"),
                }}
              >
                交付年月日
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(calc(100% - 280px)/2)",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {new Date(prescription?.createdAt)?.toLocaleDateString?.(
                  "ja-JP",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </div>
              <div
                style={{
                  padding: "10px",
                  ...border,
                  width: "180px",
                  ...flex("row", "center", "center"),
                }}
              >
                処方箋の 使用期間
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(calc(100% - 376px)/2)",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              ></div>
            </div>

            {/* MEDICINES TABLE */}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  ...border,
                  width: "30px",
                  ...flex("column", "center", "center"),
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                }}
              >
                処 方
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "118px",
                }}
              >
                変更可
              </div>
              <div
                style={{
                  padding: "10px",
                  ...border,
                  width: "calc(100% - 148px)",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <div style={{ fontSize: "11px", fontWeight: "normal" }}>
                  個々の処方薬について、後発医薬品（ジェネリック医薬品）への変更に差し支えがあると判断した場合には、
                  「変更不可」欄に「レ」又は「×」を記載し、「保険医署名」欄に署名又は記名・押印すること。
                </div>
                <div
                  style={{
                    ...flex("column", "center", "center"),
                    width: "100%",
                    paddingBlock: "5px",
                  }}
                >
                  {prescription?.medicines?.map((item, index) => (
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid grey",
                        marginTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <h4 style={{ fontSize: "inherit" }}>
                        {item?.medicine_id?.medicine_name + item?.instruction}
                      </h4>
                      <h5 style={{ fontSize: "inherit" }}>
                        {" "}
                        {item?.description}
                      </h5>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    width: "100%",
                    fontWeight: "normal",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  リフィル可　□　（　　　回） 以下余白
                </div>
              </div>
            </div>

            {/* AFTER MEDICINES  */}
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "30px",
                  writingMode: "vertical-lr",
                  textOrientation: "upright",
                  ...border,
                  ...flex("row", "center", "center"),
                }}
              >
                扁 考
              </div>
              <div
                style={{
                  display: "flex",
                  width: "calc(100% - 30px)",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    ...border,
                    ...flex("row", "center", "center"),
                    width: "calc(calc(100% - 0px)/1.5)",
                    fontSize: "11px",
                    height: "70px",
                  }}
                >
                  保険医署名 (「変更不可」欄に「レ」又は「×」を記載
                  した場合は、署名又は記名・押印すること。「変更不可」欄に「レ」又は「×」を記載
                  した場合は、署名又は記名・押印すること。)
                </div>
                <div
                  style={{
                    padding: "10px",
                    ...flex("row", "center", "center"),
                    width: "calc(calc(100% - 0px)/3)",
                    fontSize: "11px",
                    height: "70px",
                  }}
                >
                  自由診療のため医療コードはありません。
                  オンライン対応：処方せん原本は郵送します。
                </div>
                <div
                  style={{
                    padding: "10px",
                    ...flex("row", "center", "center"),
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "bold",
                    height: "20px",
                  }}
                ></div>
                <div
                  style={{
                    padding: "5px 10px",
                    ...border,
                    ...flex("row", "center", "center"),
                    width: "100%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  保険薬局が調剤時に残薬を確認した場合の対応(特に指示がある場合は「レ」又は「×」を記載すること。)
                  　　 <br />
                  □保険医療機関へ疑義照会した上で調剤　
                  　□保険医療機関へ情報提供
                </div>
              </div>
            </div>

            {/* FOOTER SECTIONS */}
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "100%",
                  fontSize: "11px",
                  textAlign: "center",
                }}
              >
                　調剤実施回数（調剤回数に応じて、□に「レ」又は「×」を記載するとともに、調剤日及び次回調剤予定日を記載すること。）
                　　 <br />
                　　□１回目調剤日（　　　年　　月　　日）　　
                □２回目調剤日（　　　年　　月　　日）　　
                □３回目調剤日（　　　年　　月　　日）
                　　　次回調剤予定日（　　年　　月　　日）　　　次回調剤予定日（　　年　　月　　日）
              </div>
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                調剤済年月日
              </div>
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                令和　　年　　月　　日
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                公費負担者番号
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              ></div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                保険薬局の所在地 及 び 名 称 保 険 薬 剤 師 氏 名
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "flex-end", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "end",
                  height: "50px",
                }}
              >
                ㊞
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                公費負担医療の 受 給 者 番 号
              </div>{" "}
              <div
                style={{
                  padding: "10px",
                  ...border,
                  ...flex("row", "center", "center"),
                  width: "calc(100% / 4)",
                  fontSize: "12px",
                  textAlign: "center",
                  height: "50px",
                }}
              ></div>
            </div>

            {/* FOOTER */}
            <div
              style={{
                padding: "0px 10px",
                ...flex("row", "center", "center"),
                width: "100%",
                fontSize: "10px",
                textAlign: "justify",
              }}
            >
              備考 <br />
              　　 1.「処方」欄には、薬名、分量、用法及び用量を記載すること。
              <br />
              　　　　２．この用紙は、Ａ列５番を標準とすること。
              <br />
              　　　　３．療養の給付及び公費負担医療に関する費用の請求に関する省令（昭和51年厚生省令第36号）第１条の公費負担医療については、「保険医療機関」とある
              　のは「公費負担医療の担当医療機関」と、「保険医氏名」とあるのは「公費負担医療の担当医氏名」と読み替えるものとすること。
            </div>
          </div>

          {/* RIGHT CONTAINER -FOR TEXT */}
          <div style={{ width: "30px" }}>
            <p
              style={{
                writingMode: "vertical-lr",
                textOrientation: "upright",
                color: "black",
                fontSize: "7px",
              }}
            >
              様 式 第 二 号 （ 第 二 十 三 条 関 係 ）
            </p>
          </div>
        </div>
        <button
          onClick={() => {
            HandlePDFFile();
          }}
          style={{ marginTop: "10px", marginBottom: "40px", marginLeft: "10px" }}
          className="text-white bg-blue-800 text-gray-700 hover:text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[16px] px-7 py-2.5 "
        >
          Print
        </button>
      </div>
    </>
  );
};

export default PreView;
