import { put, takeLatest } from "redux-saga/effects";
import { ApiCall } from "../../Services/apis";
import Swal from "sweetalert2";
import { ACTIONS } from "../Action-types";
import { setLoader } from "../Actions/GeneralActions";
import {
  getSingleUser,
  setLoginData,
  setSingleUser,
} from "../Actions/AuthActions";

function* loginRequest(params) {
  try {
    const res = yield ApiCall({
      params: params?.data?.data,
      route: "app_api/login",
      verb: "post",
    });

    console.log("login res : ", res);
    if (res?.status === 200) {
      console.log("res", res?.response);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Logged in successfully.",
        showConfirmButton: false,
        timer: 3000,
      });
      yield put(setLoginData(res?.response));
      yield put(setLoader(false));
      params?.data?.userNavigate();

      // window.location.pathname = "/";
    } else {
      console.log("error", res?.response);
      Swal.fire(
        `${res.response.message}`,
        res?.response?.message === "Please verify your account."
          ? "OTP has been sent to your email"
          : res?.response?.message ===
            "Your account has been disabled by the admin."
          ? "Please contact Admin"
          : "Try again",
        res?.response?.message === "Please verify your account."
          ? "warning"
          : "error"
      );
      if (res?.response?.message == "Please verify your account.") {
        params?.data?.userNavigate("/verify-email");
        localStorage.setItem("email", params?.data?.data?.email);
      }
      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga login error -- ", e.toString());
    yield put(setLoader(false));
  }
}
export function* loginSaga() {
  yield takeLatest(ACTIONS.LOGIN, loginRequest);
}

function* signUpRequest(params) {
  console.log("biz signup params : ", params);
  try {
    const res = yield ApiCall({
      params: params.data?.formData,
      route: "api/auth/create-Business",
      verb: "post",
    });
    if (res?.status === 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your account will be approved by admin.",
        showConfirmButton: true,
        timer: 30000,
      });

      yield put(setLoader(false));
      params.data?.navigate("/Login");
    } else {
      // alert(res?.response?.message);
      Swal.fire(res?.response?.message, "Try again", "error");
      console.log("error", res?.response);

      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga sign up err --- ", e);
    yield put(setLoader(false));
  }
}
export function* signUpSaga() {
  yield takeLatest(ACTIONS.SIGN_UP, signUpRequest);
}

function* signUpUserRequest(params) {
  console.log("param navigate route user signup : ", params?.data);
  try {
    const res = yield ApiCall({
      params: params.data?.data,
      route: "patient/signup",
      verb: "post",
    });
    if (res?.status === 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "You have successfully registered! 🌟",
        text: "OTP has been sent to your mail. Please verify your account.",
        showConfirmButton: false,
        timer: 3000,
      });
      localStorage.setItem("email", params?.data?.data?.email);
      params.data?.navigate("/verify-email");
      yield put(setLoader(false));
    } else {
      Swal.fire({
        position: "center",
        icon:
          res?.response?.message == "User is already registered."
            ? "warning"
            : "error",
        title: res?.response?.message,
        text:
          res?.response?.message == "User is already registered."
            ? "Please login if you want to access the system. Do you want to login?"
            : "Try again",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          params.data?.navigate("/login");
        }
      });

      console.log("error", res?.response);
      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga sign up user err --- ", e);
    yield put(setLoader(false));
  }
}
export function* signUpUserSaga() {
  yield takeLatest(ACTIONS.SIGN_UP_User, signUpUserRequest);
}

function* signUpGuestRequest(params) {
  yield put(setLoader(true));
  try {
    const res = yield ApiCall({
      params: params.data.params,
      route: "guest/signup",
      verb: "post",
    });
    if (res?.status === 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Sucessfully registered as Guest! 🌟",
        showConfirmButton: false,
        timer: 2000,
      });
      yield put(
        setLoginData({
          token: res?.response?.token,
          user: {
            ...params.data.params,
            user_id: res?.response?.customer?.user_id,
            guest: true,
          },
        })
      );
      yield put(setLoader(false));
      params?.data?.goo();
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res?.response?.message,
        text: "Try again",
        showConfirmButton: false,
        timer: 2000,
      });
      console.log("error", res?.response);
      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga sign up guest err --- ", e);
    yield put(setLoader(false));
  }
}
export function* signUpGuestSaga() {
  yield takeLatest(ACTIONS.SIGN_UP_GUEST, signUpGuestRequest);
}
function* GetSingleUserRequest(params) {
  console.log("params", params);
  try {
    const res = yield ApiCall({
      route: "patient/detail_patient",
      verb: "get",
      token: params?.data,
    });
    console.log(res);
    if (res?.status === 200) {
      yield put(setSingleUser(res.response?.customer));

      yield put(setLoader(false));
    } else {
      alert(res?.response?.message);
      console.log("error", res?.response);

      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga sign up err --- ", e);
    yield put(setLoader(false));
  }
}
export function* GetSingleUserSaga() {
  yield takeLatest(ACTIONS.GET_SINGLE_USER, GetSingleUserRequest);
}

// update user profile

function* UpdateUserPro(params) {
  console.log("updat user pro saga : ", params);
  try {
    yield put(setLoader(true));
    const res = yield ApiCall({
      params: params.data.data,
      route: "patient/update_patient",
      verb: "put",
      token: params?.data?.token,
    });
    // console.log("res", res);
    if (res?.status === 200) {
      // console.log("sdf");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Updated successfully.",
        showConfirmButton: true,
        timer: 30000,
      });
      yield put(getSingleUser(params.data.token));
      yield put(setLoader(false));
    } else {
      console.log("error", res?.response);
      Swal.fire(`${res.response.message}`, "Try again", "error");
      yield put(setLoader(false));
    }
  } catch (e) {
    console.log("saga sign up err --- ", e);
    yield put(setLoader(false));
  }
}
export function* UpdateUserProfileSaga() {
  yield takeLatest(ACTIONS.UPDATE_USER_PROFILE, UpdateUserPro);
}
