import React, { useEffect, useState } from "react";
import { Stethoscope } from "../../../assets/Images";
import DoctorBg from "../../../assets/Images/Doctor.png";
import DoctorImage from "../../../assets/userProfile.svg";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import DoctorDetails from "../../Modals/DoctorDetails";
import { setLoader } from "../../../Redux/Actions/GeneralActions";

const ChooseDoctor = ({ data, setData, validateDoc, docError }) => {
  console.log("doc errors", docError);
  const [DoctorList, setDoctorList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const categioryID = useSelector((state) => state.auth.categioryId);
  const token = useSelector((state) => state.auth.userToken);
  const [amount, setAmount] = useState(null);

  async function getAmount() {
    try {
      const amountResponse = await ApiCall({
        route: `price/get_price`,
        verb: "get",
      });
      console.log(amountResponse?.response?.price?.video_call);
      setAmount(amountResponse?.response?.price?.video_call);
    } catch (e) {
      console.log(e, "amount error");
    }
  }
  useEffect(() => {
    if (!amount) {
      getAmount();
    }
  }, [amount]);

  const getDoctors = async () => {
    dispatch(setLoader(true));
    setLoading(true);
    try {
      const res = await ApiCall({
        route: `doctor/doctor_listing_by_desease_id/${categioryID?.deseaseId}`,
        token: token,
        verb: "get",
      });

      if (res?.status == "200") {
        setDoctorList(res?.response?.doctors);
        dispatch(setLoader(false));
        setLoading(false);
      } else {
        console.log("your error is there");
        dispatch(setLoader(false));
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log("your error is there", e);

      // Swal.fire("invalid OTP  ", "Try again", "error");
    }
  };
  useEffect(() => {
    getDoctors();
  }, []);
  // console.log("DoctorList", DoctorList);

  return (
    <>
      <div className="third-step"></div>
      <div className="w-[100%] md:flex justify-between personal-bgg">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Choose a Doctor</h1>
            <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p>
            <div className="flex mt-[40px] border-medical">
              <h6>End to End Encrypted</h6>
              <Stethoscope className=" ml-[10px]" />
            </div>
          </div>
          {/* <img src={DoctorBg} className="md:block hidden absolute bottom-0" /> */}
        </div>
        <div className="md:w-[50%] md:pr-[68px] md:pl-[0px] pr-[20px] pl-[20px]">
          <div className="my-[15px] w-[100%] doctors-list px-[30px] py-[20px] mt-[30px] sectionScroll">
            {!loading ? (
              <>
                {DoctorList?.length > 0 ? (
                  <>
                    {DoctorList.map((item) => {
                      return (
                        <div
                          className="doctor-profile flex items-center justify-between"
                          onClick={() =>
                            setData({
                              ...data,
                              doctor_id: item?.user_id?._id,
                            })
                          }
                        >
                          <div className="rounded-[100px] mr-[10px]">
                            <input
                              checked={data?.doctor_id == item?.user_id?._id}
                              type="checkbox"
                              style={{ height: "40px" }}
                              className=" input-check "
                            />
                          </div>
                          <img
                            src={
                              item?.profile_image
                                ? item?.profile_image
                                : DoctorImage
                            }
                            className="w-[42px] h-[42px] rounded-full"
                          />
                          <div className="flex ml-[10px] items-center justify-between w-[100%]">
                            <p className="doctor-list-name">
                              {item.first_name + " " + item?.last_name}
                            </p>
                            <DoctorDetails DoctorList={{...item, amount}} />
                          </div>
                          <p className="doctor-list-special">
                            {item?.desease_id?.desease_name}
                          </p>
                        </div>
                      );
                    })}
                    {docError?.doctor_id && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "2px 0px 0px 2px",
                        }}
                      >
                        {docError?.doctor_id}
                      </p>
                    )}
                  </>
                ) : (
                  <p className="no-doctor">
                    <p className="no-doctor w-[70%]">
                      There are no doctors available for this disease now. Visit
                      Again, Thankyou.
                    </p>
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseDoctor;
