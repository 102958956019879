// PRODUCTION INSTANCE
export const REACT_APP_PAYMENT_APP_ID = "sq0idp-Xdu_hba-BSlT-RRezWPy9g";
export const PAYMENT_LOCATION_ID = "LPHTHCXMRSFTX";
export const BASE_URL = "https://www.americancliniconline.com/api";
export const PICTURE_URL = "https://www.americancliniconline.com/";
export const SOCKET_URL = "https://www.americancliniconline.com/";

// export const BASE_URL = "http://localhost:8080/api";
// export const PICTURE_URL = "http://localhost:8080/";
// export const SOCKET_URL = "ws://localhost:8080/";

// DEVELOPMENT INSTANCE
// export const REACT_APP_PAYMENT_APP_ID = "sandbox-sq0idb-ABA4L7lMgNmf4Vxzk7M8cg";
// export const PAYMENT_LOCATION_ID = "L6JJ2R0MBZ9G3";
// export const BASE_URL = "https://www.techify.world/api";
// export const PICTURE_URL = "https://www.techify.world/";
// export const SOCKET_URL = "https://www.techify.world/";

// export const BASE_URL = "https://endlessinvo.com/api";
// export const PICTURE_URL = "https://endlessinvo.com/";
// export const SOCKET_URL = "https://endlessinvo.com/";
