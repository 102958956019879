import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ChatBox.scss";
import searchIcon from "../../assets/Images/search-msg.png";
import fileIcon from "../../assets/Images/file-icon.svg";
import userIcon from "../../assets/Images/user.png";

import FileIcon from "../../assets/Images/FileIcon";
import ArrowLeft from "../../assets/arrowLeft.svg";
import { SendIcon, PDF } from "../../assets/Images";
import socket from "../../pages/messaging/socketConnection";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
import ReactLinkify from "react-linkify";
import { Spin, message } from "antd";
import { PICTURE_URL } from "../../Services/Constants";
import { useMediaQuery } from "react-responsive";
import PrimaryButton from "../../components/UI/PrimaryButton";
import { setLoader } from "../../Redux/Actions/GeneralActions";

function ChatBox({ selectedChat }) {
  const inboxRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [fileToSend, setFileToSend] = useState(null);
  const [fileKey, setFileKey] = useState(0);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  const scrollToBottom = useCallback(() => {
    const container = inboxRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [selectedChat?._id]);

  const getChatDetails = async () => {
    setIsMessagesLoading(true);

    try {
      const res = await ApiCall({
        route: `chat/chat_detail/${selectedChat._id}`,
        token: token,
        verb: "get",
      });

      if (res?.status == "200") {
        setMessages(res.response?.chat?.messages);
        setFilteredMessages(res.response?.chat?.messages);

        res.response?.chat?.messages?.map?.((el) => {
          if (el?.sender !== user?.user_id && el?.status !== "seen") {
            socket.emit("messageSeen", {
              conversationId: selectedChat?._id,
              messageId: el?._id,
            });
          }
        });

        setIsMessagesLoading(false);
      } else {
        console.log("Couldn't get chat details");
        setIsMessagesLoading(false);
      }
    } catch (e) {
      console.log("Error while getting chat details.", e);
      setIsMessagesLoading(false);
    }
  };

  useEffect(() => {
    if (selectedChat?._id) {
      console.log("running console in chat in video");
      getChatDetails();

      socket.emit("join", { conversationId: selectedChat?._id });

      const handleChatMessage = (payload) => {
        console.log(payload, "incoming message");
        if (payload?.chatId === selectedChat?._id) {
          setMessages((prev) => [...prev, payload]);
          setFilteredMessages((prev) => [...prev, payload]);

          if (payload?.sender !== user?.user_id) {
            socket.emit("messageSeen", {
              conversationId: selectedChat?._id,
              messageId: payload?._id,
            });
          }
        }
      };

      socket.on("chat", handleChatMessage);
      return () => {
        socket.off("chat", handleChatMessage);
      };
    }
  }, [selectedChat?._id]);

  useEffect(() => {
    scrollToBottom();
  }, [filteredMessages]);

  const sendMessage = (text) => {
    socket.emit("chat", {
      conversationId: selectedChat?._id,
      text,
      senderId: user?.user_id,
      sender_role: "Patient",
    });
  };

  const sendFile = () => {
    socket.emit(
      "upload",
      fileToSend,
      fileToSend?.name,
      fileToSend.type,
      user?.user_id,
      selectedChat?._id,
      (status) => {
        console.log(status, "status");
      },
      "Patient"
    );
  };

  socket.on("messageSeen", (payload) => {
    const updatedMessages = messages.map((message) => {
      if (message?._id === payload?._id) {
        return payload;
      } else {
        return message;
      }
    });
    setMessages(updatedMessages);
    setFilteredMessages(updatedMessages);
  });

  const handleSendMessage = () => {
    if (!fileToSend) {
      if (messageText?.trim() !== "") sendMessage(messageText);
      setMessageText("");
    } else {
      sendFile();
      setFileToSend(null);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const [maxHeight, setMaxHeight] = useState("calc(100vh - 270px)"); // Initial value, can be adjusted

  useEffect(() => {
    const calculateMaxHeight = () => {
      const innerHeight = window.innerHeight;
      setMaxHeight(`calc(${innerHeight}px - 240px)`); // Adjust the value as needed
    };

    calculateMaxHeight(); // Initial calculation
    window.addEventListener("resize", calculateMaxHeight);

    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, []);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type === "application/pdf" || file.type.startsWith("image/")) {
      setFileToSend(file);
      setFileKey((prevKey) => prevKey + 1);
    } else {
      message.error("Only Images and PDF files Allowed");
    }
  };

  return (
    <div className="video-chat-box-main-container">
      <div className="messages-box-header">
        {selectedChat ? (
          <div className="messages-box-header-left">
            <div className={`selected-single-chat-user-container `}>
              <div className="single-chat-user-left">
                <img
                  src={
                    selectedChat?.booking?.doctor_id?.profile_image || userIcon
                  }
                  alt={selectedChat?.booking?.doctor_id?.first_name}
                />
              </div>

              <div className="single-chat-user-right">
                <h5>
                  {`${
                    selectedChat?.booking?.doctor_id?.first_name
                      ? "Dr." +
                        " " +
                        selectedChat?.booking?.doctor_id?.first_name +
                        " " +
                        selectedChat?.booking?.doctor_id?.last_name
                      : "American Clinic"
                  }`}
                </h5>

                {/* <a href={`/patient/details/${selectedChat?.booking?._id}`}> */}
                <h4>{selectedChat?.booking?.category_id?.category_name}</h4>
                {/* </a> */}
              </div>
            </div>
          </div>
        ) : (
          <h3>Messages</h3>
        )}
      </div>
      <hr />
      {!isMessagesLoading ? (
        <>
          {selectedChat && (
            <>
              <div
                className="chat-box-messages"
                ref={inboxRef}
                style={{ maxHeight }}
              >
                {filteredMessages?.length === 0 ? (
                  <div className="no-messages-text">
                    <p>
                      Please wait! You are in queue. <br />{" "}
                    </p>{" "}
                    <p className="privacy-text-inchat">
                      {" "}
                      Communicate freely with doctors, your chat is encrypted to
                      ensure privacy .
                    </p>
                  </div>
                ) : (
                  filteredMessages?.map((message, index) => {
                    const messageDate = formatDate(message?.date);
                    const prevMessageDate =
                      index > 0
                        ? formatDate(filteredMessages[index - 1]?.date)
                        : null;

                    return (
                      <React.Fragment key={message?._id}>
                        {index === 0 || messageDate !== prevMessageDate ? (
                          <div className="messages-date-divider">
                            {messageDate}
                          </div>
                        ) : null}
                        {message?.sender === user?.user_id ? (
                          <div className="single-message-item sender-message-item">
                            <div className="single-message-text-box">
                              {message?.file?.url ? (
                                message?.file?.file_type?.startsWith(
                                  "image"
                                ) ? (
                                  <a
                                    href={`${PICTURE_URL}${message?.file?.url}`}
                                    target="_blank"
                                    download
                                  >
                                    <img
                                      src={`${PICTURE_URL}${message?.file?.url}`}
                                      className="message-image"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href={`${PICTURE_URL}${message?.file?.url}`}
                                    target="_blank"
                                    download
                                  >
                                    <PDF />
                                  </a>
                                )
                              ) : (
                                <ReactLinkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      className="message-as-link"
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  <p>{message?.message}</p>
                                </ReactLinkify>
                              )}
                              <span>
                                {new Date(message?.date).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}{" "}
                                <i
                                  className={
                                    message?.status === "seen"
                                      ? "seen"
                                      : message?.status == "delivered"
                                      ? "delivered"
                                      : ""
                                  }
                                >
                                  {message?.status}
                                </i>
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="single-message-item">
                            <img
                              src={
                                selectedChat?.booking?.doctor_id
                                  ?.profile_image || userIcon
                              }
                              alt="Profile"
                              className="message-profile-image"
                            />
                            <div className="single-message-text-box">
                              {message?.sender_role && (
                                <h6>{message?.sender_role}</h6>
                              )}
                              {message?.file?.url ? (
                                message?.file?.file_type?.startsWith(
                                  "image"
                                ) ? (
                                  <a
                                    href={`${PICTURE_URL}${message?.file?.url}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={`${PICTURE_URL}${message?.file?.url}`}
                                      className="message-image"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href={`${PICTURE_URL}${message?.file?.url}`}
                                    target="_blank"
                                  >
                                    <PDF />
                                  </a>
                                )
                              ) : (
                                <ReactLinkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      className="message-as-link"
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  <p>{message?.message}</p>
                                </ReactLinkify>
                              )}
                              <span>
                                {new Date(message?.date).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </div>
              <div className="chat-box-messages-sending-container">
                <div className="messages-inputs-container">
                  {!fileToSend ? (
                    <input
                      placeholder="Type something..."
                      type="text"
                      disabled={fileToSend ? true : false}
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      onKeyDown={handleInputKeyDown}
                    />
                  ) : (
                    <div className="file-name">
                      <span>
                        {fileToSend?.name.length > 25
                          ? `${fileToSend?.name.slice(0, 25)}.......`
                          : fileToSend?.name}
                      </span>
                      <span
                        className="cross-file-name"
                        onClick={() => setFileToSend(null)}
                      >
                        X
                      </span>
                    </div>
                  )}
                  <input
                    id="message-file-icon"
                    key={fileKey}
                    type="file"
                    hidden
                    style={{ display: "none" }}
                    accept="image/*,application/pdf"
                    onChange={handleFileInputChange}
                    // onChange={(e) => {
                    //   if (
                    //     e.target?.files[0]?.type === "application/pdf" ||
                    //     e.target?.files[0]?.type.split("/")[0] === "image"
                    //   ) {
                    //     setFileToSend(e.target.files[0]);
                    //     setFileKey((prevKey) => prevKey + 1);
                    //     return;
                    //   }
                    //   message.error("Only Images and PDF files Allowed");
                    // }}
                  ></input>

                  <label className="file-icon" htmlFor="message-file-icon">
                    <img
                      height={20}
                      width={20}
                      src={fileIcon}
                      alt="file-icon"
                    />
                  </label>
                </div>
                <PrimaryButton icon={<SendIcon />} onClick={handleSendMessage}>
                  Send
                </PrimaryButton>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="no-chat-message ">
          <Spin />{" "}
        </div>
      )}
    </div>
  );
}

export default ChatBox;
