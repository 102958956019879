// import React, { useState } from "react";
// import { Button, Drawer } from "antd";
// import ChatInVideo from "./ChatInVideo";
// import messageIcon from "../../assets/messageIcon.svg";
// import ChatBox from "./ChatBox";

// const ChatDrawer = (selectedChat) => {
//   const [open, setOpen] = useState(false);
//   console.log("selectedChat", selectedChat?.selectedChat?._id);
//   const showDrawer = () => {
//     setOpen(true);
//     console.log("Device Width:", window.innerWidth);
//   };
//   const onClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       {/* <Button
//         className="border border-blue-500 rounded-[10px] text-blue-500 h-[65px] w-[242px] "
//         type="primary"
//         onClick={showDrawer}
//       >
//         <i class="fa-solid fa-file-medical px-2"></i>
//         Chat
//       </Button> */}
//       <button
//         className="chat-control-button "
//         width={40}
//         height={40}
//         // src={messageIcon}
//         onClick={showDrawer}
//         title="Message doctor"
//       >
//         <i class="fa-solid fa-message"></i>
//       </button>
//       <Drawer
//         title="Chat"
//         placement="right"
//         width={window.innerWidth}
//         size={window.innerWidth}
//         onClose={onClose}
//         open={open}
//       >
//         <div>
//           {open && (
//             <ChatBox
//               selectedChat={{
//                 _id: selectedChat?.selectedChat?._id,
//                 booking: selectedChat?.selectedChat?.booking,
//               }}
//             />
//           )}
//         </div>
//         <div className="border-t border-gray-400 my-2  "></div>
//       </Drawer>
//     </>
//   );
// };

// export default ChatDrawer;




////////////
///////////


import React, { useState, useEffect } from "react";
import { Button, Drawer } from "antd";
import ChatInVideo from "./ChatInVideo";
import messageIcon from "../../assets/messageIcon.svg";
import ChatBox from "./ChatBox";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";

const ChatDrawer = (selectedChat) => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const token = useSelector((state) => state.auth.userToken);

  // Get notifications when component mounts and when chat opens/closes
  useEffect(() => {
    getNotifications();
  }, [open]);

  const getNotifications = async () => {
    try {
      const res = await ApiCall({
        route: `chat/get_notifications`,
        token: token,
        verb: "get",
      });
      if (res?.status === 200) {
        setNotifications(res?.response?.notifications);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Function to count unread messages for the selected chat
  const hasUnreadMessages = () => {
    return notifications.some(
      notification => 
        notification.chatId === selectedChat?.selectedChat?._id && 
        !notification.isRead
    );
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="relative">
        <button
          className="chat-control-button"
          onClick={showDrawer}
          title="Message doctor"
        >
          <i className="fa-solid fa-message"></i>
          {/* Notification dot */}
          {hasUnreadMessages() && (
            <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full"></span>
          )}
        </button>
      </div>

      <Drawer
        title="Chat"
        placement="right"
        width={window.innerWidth}
        size={window.innerWidth}
        onClose={onClose}
        open={open}
      >
        <div>
          {open && (
            <ChatBox
              selectedChat={{
                _id: selectedChat?.selectedChat?._id,
                booking: selectedChat?.selectedChat?.booking,
              }}
              setNotifications={setNotifications}
              notifications={notifications}
            />
          )}
        </div>
        <div className="border-t border-gray-400 my-2"></div>
      </Drawer>
    </>
  );
};

export default ChatDrawer;