import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { ApiCall } from "../../Services/apis";
import Nav from "../../components/Patient/Nav/Nav";
import PrimaryButton from "../../components/UI/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData } from "../../Redux/Actions/AuthActions";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const category = useSelector((state) => state?.auth?.categioryId);
  const navigate = useNavigate();
  const [verification_code, setverification_code] = useState("");
  const handleChange = (verification_code) =>
    setverification_code(verification_code);

  console.log(category, "category");

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email]);

  const handleSubmitOtp = async () => {
    try {
      const res = await ApiCall({
        route: `app_api/code_verification`,
        verb: "post",
        params: { verification_code, email },
      });

      if (res?.status == "200") {
        // console.log(res?.response, "rsonsensn");
        if (category?.categorieId) {
          console.log("category-worked");
          navigate("/urgent-appointment");
        } else {
          // navigate("/prescription/urgent");
          navigate("/");
        }
        dispatch(setLoginData({ ...res?.response, prescriptionRequest: true }));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "You have successfully verified your account!",
          text: "Please select your current medical condition to get prescription.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire("OTP does not match ", "Try again", "error");
      }
    } catch (e) {
      Swal.fire("Invalid OTP  ", "Try again", "error");
    }
  };

  const handleSubmit = async (e) => {
    try {
      const res = await ApiCall({
        route: `app_api/email_verification`,
        verb: "put",
        params: { email: email, role: "patient" },
      });

      if (res?.status == "200") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "OTP sent",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire(res?.response?.message, "Try again", "error");
      }
    } catch (e) {
      Swal.fire("Network Error ", "Try again", "error");
    }
  };

  return (
    <>
      <Nav />

      <div className="pt-9 pb-24 md:w-[45%] mx-auto">
        <div>
          <div className="card-shadow bg-transparent rounded px-[40px] pt-[30px]">
            <>
              <div>
                <div className="flex justify-center">
                  <div className="w-[80%]">
                    <h1 className="text-[#2F2E2E] text-center  md:text-[20px] leading-[64px] font-semibold ">
                      Enter OTP
                    </h1>
                    <h2 className="text-[#2F2E2E] py-[10px] text-center text-[14px] leadind-[200%]  ">
                      We have sent the OTP to the email address. Please check
                      your email.
                    </h2>
                  </div>
                </div>
                <div>
                  <form>
                    <div className="mb-3 pt-[40px]">
                      <div className="flex justify-center">
                        <OtpInput
                          value={verification_code}
                          onChange={handleChange}
                          numInputs={6}
                          separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={{
                            border: "2px solid #000",
                            borderRadius: "8px",
                            width: "50px",
                            height: "50px",
                            fontSize: "18px",
                            color: "#000",
                            fontWeight: "400",
                            background: "#ffff",
                            caretColor: "blue",
                          }}
                          focusStyle={{
                            border: "3px solid #002fa7",
                            outline: "none",
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-[10px] cursor-pointer ">
                      {" "}
                      <h2
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                          setverification_code(null);
                        }}
                        className="text-sm text-[#002fa7] font-medium"
                      >
                        Resend OTP
                      </h2>{" "}
                    </div>

                    <div className=" py-8 flex justify-center   ">
                      {/* <Link to='/ResetPassword' > */}{" "}
                      <PrimaryButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmitOtp({ e, verification_code });
                        }}

                        // className="text-white my-6  bg-[#002fa7]   outline-0  font-medium rounded-xl text-sm px-12 h-[50px] text-center  "
                      >
                        Verify
                      </PrimaryButton>{" "}
                      {/* </Link> */}
                    </div>
                  </form>
                </div>
              </div>
            </>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
