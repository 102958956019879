import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Room from "./Room";
import { ApiCall } from "../../Services/apis";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { Input, Skeleton } from "antd";
import Swal from "sweetalert2";
import { setLoginData } from "../../Redux/Actions/AuthActions";
import "./VideoChat.scss";
import ZoomVideo from "@zoom/videosdk";

const VideoChat = () => {
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const [videoToken, setVideoToken] = useState(null);
  const [isJoining, setIsJoining] = useState(false);
  const dispatch = useDispatch();
  const role = user?.role;
  const navigate = useNavigate();
  const queryUrl = new URLSearchParams(useLocation().search);
  const roomId = queryUrl.get("roomId");
  const userName = queryUrl.get("name");
  const [room, setRoom] = useState(null);
  const [patientlink, setPatientLink] = useState();
  var zoomClient = ZoomVideo.createClient();
  var zoomSession;
  const GetPatientLink = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await ApiCall({
        route: `patient/get_patient_by_PID/${roomId}&${id}`,
        token: token,
        verb: "get",
      });

      if (res?.status == 200) {
        dispatch(
          setLoginData({
            token: res?.response?.token,
            user: res?.response?.patient,
          })
        );
        dispatch(setLoader(false));

        Swal.fire({
          title: "Video call joining...",
          text: "Please wait.",
          showConfirmButton: false,
          timer: 1000,
        });
        if (res?.response?.token && token) {
          getVideoToken();
        }
        console.log(`${res?.response?.message} "suc", "Successfuly"`);
      } else {
        dispatch(setLoader(false));

        Swal.fire({
          icon: "error",
          title: "video call can't be processed.",
          text: "Please try again.",
          showConfirmButton: false,
          timer: 1000,
        });
        console.log(`${res?.response?.message} "Try again", "error"`);
      }
    } catch (error) {
      Swal.fire(error?.response?.message, "Try again", "error");
      dispatch(setLoader(false));
    }
  };

  const errorModal = () => {
    dispatch(setLoader(false));
    Swal.fire({
      title: "Please use correct link!",
      text: "Either your video call link is broken or you are not authorized to join this call.",
      showConfirmButton: true,
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
  };

  const connectVideo = async (token) => {
    if (isJoining) return; // Prevent multiple join attempts
    setIsJoining(true);

    try {
      // await zoomClient.init("en-US", "Global");
      await zoomClient.init("en-US", "Global", { patchJsMedia: true, enforceMultipleVideos:true });
      await zoomClient.join(roomId, token || videoToken, userName);
      zoomSession = zoomClient.getMediaStream();
      setRoom(zoomSession);
    } catch (error) {
      console.log("Error joining Zoom patient session:", error);
      setIsJoining(false); // Reset joining status on error
    }
  };

  const getVideoToken = async () => {
    try {
      const res = await ApiCall({
        route: `app_api/video_token`,
        token: token,
        verb: "post",
        params: {
          name: userName,
          booking_id: roomId,
        },
      });

      if (res?.status == "200") {
        setVideoToken(res.response.token.token);
        connectVideo(res.response.token.token);
      } else {
        console.log("your error is there", res?.response?.message);
        errorModal();
      }
    } catch (e) {
      console.log("your error is there", e);
      errorModal();
    }
  };

  useEffect(() => {
    // connect to video
    if (user && user?.user_id === userName && token) {
      getVideoToken();
    } else {
      if (!user) {
        console.log("user pid");
      } else {
        console.log("model is open");
        // errorModal();
      }
    }
  }, [roomId, userName]);

  const handleLogout = useCallback(
    async (runNavigation, navigateUrl) => {
      await zoomClient?.leave?.();
      if (runNavigation) {
        window.location.pathname = navigateUrl || `/review/${roomId}`;
      }
      setRoom(null);
    },
    [navigate]
  );

  useEffect(() => {
    const tidyUp = (event) => {
      if (room) {
        handleLogout();
      }
    };
    window.addEventListener("pagehide", tidyUp);
    window.addEventListener("beforeunload", tidyUp);
    return () => {
      window.removeEventListener("pagehide", tidyUp);
      window.removeEventListener("beforeunload", tidyUp);
    };
  }, [room, handleLogout]);

  let render;
  if (room) {
    render = (
      <Room
        userId={userName}
        roomName={roomId}
        room={room}
        handleLogout={handleLogout}
        role={role}
        roomId={roomId}
      />
    );
  } else {
    render = (
      <div>
        {user ? (
          <div className="p-[30px] flex gap-[30px]">
            <Skeleton.Image
              active={true}
              style={{
                height: "calc(100vh - 60px)",
                width: "67vw",
              }}
            />
            <Skeleton
              avatar
              paragraph={{
                rows: 19,
              }}
              style={{
                height: "calc(100vh - 60px)",
                width: "30vw",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              backgroundColor: "#bdbdbd",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="px-6 py-4 rounded-lg place-content-stretch  bg-[#fff] w-[400px] h-[250px] mt-[10%] ">
              <h3 className=" text-[#0D0D0D] font-medium mt-[30px] text-center">
                Enter patient PID (Patient ID)
              </h3>
              <p>
                You can get patient id (PID) from your American Clinic Online
                porfile. Patient ID will also be mentioned in e-mail received
                for video call.
              </p>
              <div className="mt-[5%]">
                <Input
                  value={patientlink}
                  onChange={(e) => setPatientLink(e.target.value)}
                  type="text"
                  placeholder="Patient ID"
                  style={{
                    marginTop: "10px",
                  }}
                />
                <div className="flex justify-end mt-[5%]">
                  <button
                    type="text"
                    className="bg-[#002AFF] text-[#fff] rounded-lg mt-[10px] px-[10px] py-[6px]  "
                    onClick={() => {
                      GetPatientLink(patientlink);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return render;
};

export default VideoChat;
