import "./DetailsPage.css";
import {
  MedIcon,
  Thermameter,
  History,
  Umbrella,
} from "../../assets/Images/index";

const MedHistory = ({ bookingDetail }) => {
  console.log("med", bookingDetail);
  return (
    <>
      {bookingDetail?.medical_history?.booking_reason ? (
        <div className="px-10 py-4">
          <div className="space-y-2 mb-4">
            <h3 className="text-[#0A0A0A] text-sm font-[Adamina] font-bold">
              What brings you here today?
            </h3>
            {bookingDetail.medical_history?.booking_reason === "" ? (
              <p className="text-[#828282]">Not provided</p>
            ) : (
              <p className="text-[#828282]">
                {bookingDetail?.medical_history?.booking_reason}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="space-y-2">
              <h3 className="text-[#0A0A0A] text-sm font-[Adamina] font-bold">
                Existing Allergies
              </h3>
              {bookingDetail?.medical_history?.allergy.length === 0 ? (
                <p className="text-[#828282]">No Allergies</p>
              ) : (
                <ol className="list-decimal pl-5 space-y-1">
                  {bookingDetail?.medical_history?.allergy.map((data) => (
                    <li key={data} className="text-[#828282]">
                      {data}
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </div>

          <div className="mb-4">
            <div className="space-y-2">
              <h3 className="text-[#0A0A0A] text-sm font-[Adamina] font-bold">
                Existing Medications:
              </h3>
              <div className="medications-list">
                {bookingDetail?.medical_history?.medication == null ? (
                  <p className="text-[#828282]">Not yet preferred</p>
                ) : (
                  <ol className="list-decimal pl-5 space-y-1">
                    {bookingDetail?.medical_history?.medication.map((item) => (
                      <li key={item} className="text-[#828282]">
                        {item}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            </div>
          </div>

          {bookingDetail?.personal_info?.gender !== "male" && (
            <div className="mb-4">
              <div className="space-y-2">
                <h3 className="text-[#0A0A0A] text-sm font-[Adamina] font-bold">
                  Pregnancy status
                </h3>
                <ul className="space-y-1">
                  <li className="text-[#828282]">
                    {bookingDetail?.medical_history?.pregnancy_status}
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="mb-4">
            <div className="space-y-2">
              <h3 className="text-[#0A0A0A] text-sm font-[Adamina] font-bold">
                Past Medical History
              </h3>
              {bookingDetail?.medical_history?.past_history == null ? (
                <p className="text-[#828282]">No Previous Medical Record</p>
              ) : (
                <p className="text-[#828282]">
                  {bookingDetail?.medical_history?.past_history}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full py-12">
          <div className="flex justify-center items-center">
            <p className="text-slate-400 text-xl font-semibold text-center">
              You have not provided any medical history!
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MedHistory;
