import React from "react";
import UserProfile from "../../assets/Images/userProfile.svg";

const DoctorDetailsInformation = ({ bookingDetail }) => {
  console.log("doctor details in doc section =>", bookingDetail);
  return (
    <>
      <div className="personal-info">
        <div className="image-cont">
          <img
            src={bookingDetail?.doctor_id?.profile_image || UserProfile}
            className="profile-image-user"
          />
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold">
              Full Name:
            </span>
            <span className="text-[#828282]">
              {bookingDetail?.doctor_id?.first_name}{" "}
              {bookingDetail?.doctor_id?.last_name}{" "}
            </span>
          </div>

          <div className="flex items-center space-x-2">
            <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold">
              Email Address:
            </span>
            <span className="text-[#828282]">
              {bookingDetail?.doctor_id?.email}
            </span>
          </div>

          {/* <div className="md:flex md:justify-between flex md:pt-[10px] md:gap-3">
                <h3 className="questionss">Date of birth:</h3>
                <p className="answer">{bookingDetail?.doctor_id?.dob}</p>
              </div> */}
          {/* <div className="flex md:pt-[10px] md:gap-3 ">
                <h3 className="questionss ">Email:</h3>
                <p className="answer">{bookingDetail?.doctor_id?.email} </p>
              </div> */}
          {/* <div className="space-y-2"> */}
          <div className="flex items-center space-x-2">
            <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold">
              Phone Number:
            </span>
            <span className="text-[#828282]">
              {bookingDetail?.doctor_id?.phone_number}
            </span>
          </div>
          {/* </div> */}

          {/* <div className="space-y-2"> */}
          <div className="flex items-center space-x-2">
            <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold">
              Speciality:
            </span>
            <div>
              {bookingDetail?.doctor_id?.deseases.map((data) => (
                <span className="text-[#828282]">
                  {data?.desease_name + ", "}
                </span>
              ))}
            </div>
          </div>
          {/* </div> */}

          {/* <div className="flex  md:pt-[10px] md:gap-3">
                <h3 className="questionss">Medical issues:</h3>
                <p className="answer"></p>
              </div> */}
        </div>
      </div>
    </>
  );
};

export default DoctorDetailsInformation;
