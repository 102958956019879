import React, { useState, useRef, useEffect } from "react";
import "./Account.css";
import Nav from "../../../components/Patient/Nav/Nav";
import AvatarEditor from "react-avatar-editor";

import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

import { TextField } from "@mui/material";
import moment from "moment";
import { Form, Modal, message } from "antd";

import { DatePicker, Divider, Select } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../Redux/Actions/AuthActions";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import PhoneNumber from "../../../components/Common/PhoneNumber";
import UserDp from "../../../assets/userProfile.svg";
import { Input } from "antd";
import { ApiCall } from "../../../Services/apis";
import Swal from "sweetalert2";
import { he } from "date-fns/locale";
import { useMediaQuery } from "react-responsive";
import PrimaryButton from "../../../components/UI/PrimaryButton";
import { useLoadScript } from "@react-google-maps/api";
const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

export const Account = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const editor = useRef(null);
  const [phoneNum, setphoneNum] = useState(user?.phone_number);
  const [data, setData] = useState(user);
  const [images, setImages] = useState(null);
  const [value, setValue] = useState(data?.dob);
  const [gender, setGender] = useState(user?.gender);
  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();
  const [location, setLocation] = useState(user?.location?.address);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [imageEditingData, setImageEdtingData] = useState({
    scale: 1.1,
    rotate: 0,
  });
  const [locate, setLocate] = useState();
  const [locationAvailable, setLocationAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [location_label, setLocation_label] = useState(user?.location?.address);
  const [latitude, setLatitude] = useState(user?.location?.coordinates[0]);
  const [longitude, setLongitude] = useState(user?.location?.coordinates[1]);
  const confirmPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);

  const onFileChange = (e) => {
    const [images] = e.target.files;
    setImages(images);
    if (images) {
      setShowImageEditModal(true);
    }
    e.target.value = null;
  };

  const handleChange = (value) => {
    setGender(value);
    console.log(value);
  };
  const onChange = (date, dateString) => {
    setData({
      ...data,
      dob: dateString,
    });
  };
  // console.log("data",data)
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const dateFormat = "YYYY-MM-DD";

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const getSuggestion = async (val) => {
    setLocation_label(val);
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=${access_token}`
    )
      .then((response) => response.json())
      .then((data) => {
        data?.features == undefined ? setshown(false) : setshown(true);
        setSuggetions(data?.features);
      });
  };

  // Load the Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    // libraries,
  });

  const getAddressFromCoordinates = async (latitude, longitude) => {
    try {
      setIsLoading(true);
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

      const response = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              resolve(results[0]);
            } else {
              reject(new Error("No results found"));
            }
          } else {
            reject(new Error("Geocoder failed: " + status));
          }
        });
      });

      setLocation_label(response.formatted_address);
      setLocation(response.formatted_address);
      setLatitude(latitude);
      setLongitude(longitude);

      console.log("Location found:", response.formatted_address);
    } catch (error) {
      console.error("Error getting address:", error);
      setError("Failed to get address details");
    } finally {
      setIsLoading(false);
    }
  };

  const getlocationPermison = async () => {
    setError("");
    console.log("Getting location permission");

    if (!navigator.geolocation) {
      message.error("Geolocation is not supported by your browser");
      return;
    }

    try {
      setIsLoading(true);
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      });

      console.log("Position received:", position);
      await getAddressFromCoordinates(
        position.coords.latitude,
        position.coords.longitude
      );
    } catch (error) {
      console.error("Error getting location:", error);
      if (error.code === 1) {
        message.error(
          "Location permission denied. Please enable location access in your browser and system settings."
        );
      } else if (error.code === 2) {
        message.error("Location unavailable. Please try again.");
      } else {
        message.error("Failed to get your location. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Render loading and error states
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  const handleSubmitImage = async () => {
    const getImageBlob = async () => {
      const dataUrl = editor.current.getImage().toDataURL();
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return blob;
    };

    const imageBlob = await getImageBlob();
    setShowImageEditModal(false);
    console.log(imageBlob, "imageBlob");
    await handleSubmit(imageBlob);
  };

  const handleSubmit = async (image) => {
    // const date = new Date(value).toLocaleDateString("en-US");
    // console.log("date", date);
    const formData = new FormData();
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    // formData.append("dob", data?.dob);
    formData.append("profile_image", image || data?.profile_image);
    formData.append("phone_number", phoneNum);
    formData.append("japan_email", data?.japan_email);
    formData.append("gender", gender);
    formData.append(
      "location",
      `{
      "address": "${location || ""}",
      "coordinates": [${longitude || 0}, ${latitude || 0}]
    }`
    );
    // formData.append("coordinates", [longitude, latitude]);
    dispatch(UpdateUserProfile({ token, data: formData }));
    setImages(null);
  };
  console.log(location_label);

  const handlePassword = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("old_password", data?.old_password);
    formData.append("new_password", data?.new_password);
    formData.append("confirm_password", data?.confirm_password);

    try {
      const res = await ApiCall({
        route: `app_api/change_password`,
        verb: "put",
        token: token,
        params: formData,
      });
      if (res?.status == "200") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Password has been updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        dispatch(setLoader(false));
      } else {
        Swal.fire({
          icon: "error",
          title: `Unable to update password!`,
          text: `${res?.response?.message}`,
        });
        dispatch(setLoader(false));

        console.log("error", res?.response);
      }
    } catch (e) {
      console.log("saga sign up err --- ", e);
    }
  };

  return (
    <section className="">
      <Nav />
      <div className="mt-[100px] ">
        {/* <center>
          <h1>Profile Settings</h1>
          
        </center> */}
        <form
          onChange={({ target: { value, id, files } }) => {
            setData({
              ...data,
              ...(id && { [id]: value }),
              ...(id === "profile_image" && { [id]: files[0] }),
            });
          }}
          className="w-full d-flex justify-center"
        >
          <div className="md:px-[0px] px-[20px] mt-[15px] md:flex  md:w-[80%] w-full justify-between flex-wrap">
            <div className="md:w-[18%] flex updateProfileimg">
              <center>
                {user.profile_image === null ? (
                  UserDp
                ) : (
                  <img
                    src={
                      (images && URL.createObjectURL(images)) ||
                      (user?.profile_image && user?.profile_image) ||
                      UserDp
                    }
                    className="mt-[10px] md:h-[155px] md:w-[155px] object-cover  rounded-[50%]"
                    onChange={onFileChange}
                  />
                )}
                <p className="profile-image-text mt-3 ">Profile Image</p>
                <label
                  htmlFor="profile_image"
                  className="browse-image mt-[15px] md:mb-[0px] mb-[15px]  flex justify-center items-center cursor-pointer"
                >
                  Change Image
                  <input
                    type="file"
                    onChange={onFileChange}
                    id="profile_image"
                    className="shadow-sm hidden h-[40px] border border-gray-300 text-gray-900 text-xs rounded-2xl bg-[#FBFEFF] h-[40px]  w-full p-2.5 focus:bg-white   "
                    required
                    accept="image/*"
                  />
                </label>
              </center>
            </div>
            <div className="md:w-[35%] md:ml-2  w-[100%] updateFieldInputs account-form-container pb-[20px]">
              {!isMobile && (
                <div className="flex justify-end">
                  <PrimaryButton
                    //
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      dispatch(setLoader(true));
                    }}
                    className="md:mr-[55px]"
                  >
                    Update Profile
                  </PrimaryButton>
                </div>
              )}
              <div className="form-item-account">
                <label>First Name</label>
                <Input
                  placeholder="Given Name"
                  id="first_name"
                  value={data?.first_name}
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="form-item-account">
                <label>Surname</label>
                <Input
                  placeholder="SurName"
                  id="last_name"
                  value={data?.last_name}
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              {/* <DatePicker
                onChange={onChange}
                defaultValue={moment(
                  user?.dob ? user?.dob : new Date(),
                  dateFormat
                )}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                id="dob"
                style={{
                  
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
                placeholder="date of birth"
              /> */}
              <div className="form-item-account">
                <label>Email</label>
                <Input
                  placeholder="Email Address"
                  id="email"
                  disabled={true}
                  value={user?.email}
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="form-item-account">
                <label>PID</label>
                <Input
                  placeholder="Pid"
                  id="pid"
                  disabled={true}
                  value={user?.PID}
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              {user?.gender ? (
                <>
                  <div className="form-item-account">
                    <label>Gender</label>
                    <Select
                      placeholder="Select Gender"
                      id="gender"
                      style={{
                        width: "85%",
                        height: "42px",
                        borderRadius: "10px",
                      }}
                      onChange={handleChange}
                      value={gender}
                      options={[
                        {
                          value: "male",
                          label: "Male",
                        },
                        {
                          value: "female",
                          label: "Female",
                        },
                      ]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="form-item-account">
                    <label>Gender</label>
                    <Select
                      placeholder="Select Gender"
                      id="gender"
                      style={{
                        width: "85%",
                        height: "42px",
                        borderRadius: "10px",
                      }}
                      onChange={handleChange}
                      options={[
                        {
                          value: "male",
                          label: "Male",
                        },
                        {
                          value: "female",
                          label: "Female",
                        },
                      ]}
                    />
                  </div>
                </>
              )}
              <div className="form-item-account">
                <label>Reachable Address</label>
                <Input
                  placeholder="Your reachable address in Japan"
                  id="japan_email"
                  defaultValue={
                    data?.japan_email == "undefined" ? "" : data?.japan_email
                  }
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="form-item-account">
                <label>Phone Number</label>
                <Input
                  placeholder="Phone Number"
                  onChange={(e) => setphoneNum(e.target.value)}
                  value={phoneNum}
                  type="number"
                  autoComplete="off"
                  style={{
                    width: "85%",
                    height: "42px",
                    borderRadius: "10px",
                  }}
                />
              </div>

              {/* <PhoneNumber setphoneNum={setphoneNum} /> */}
              <div className="form-item-account">
                <label>Geolocation</label>{" "}
                <>
                  <div
                    className="bg-[#fff] updateProfAddress"
                    style={{
                      width: "85%",
                      height: "42px",
                      borderRadius: "10px",
                      border: "1px solid #d9d9d9",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        getlocationPermison();
                        setshown(false);
                      }}
                      style={{
                        height: "40px",
                        borderRadius: "10px 0px 0px 10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                        cursor: "pointer",
                        width: "100%",
                        ...(!location_label && { color: "grey" }),
                      }}
                    >
                      {location_label || "Click to get your location"}
                    </div>

                    <div
                      onClick={() => {
                        getlocationPermison();
                        setshown(false);
                      }}
                      className="bg-[#e8f4fe]  cursor-pointer w-[4rem]  rounded-r-[10px]  flex justify-center items-center"
                    >
                      <i className="fa-solid fa-location-crosshairs text-[#002fa7]   text-2xl   "></i>
                    </div>
                  </div>
                </>
              </div>

              {isMobile && (
                <div className="flex justify-end">
                  <PrimaryButton
                    //
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      dispatch(setLoader(true));
                    }}
                    className=" md:mr-[55px] mb-[15px]"
                  >
                    Update Profile
                  </PrimaryButton>
                </div>
              )}
            </div>

            <span className="divider"></span>

            <div className="md:w-[35%] w-[100%] md:ml-[1\0px] updateFieldInputs account-form-container">
              <div className="form-item-account">
                <label>Old Password</label>{" "}
                <Input.Password
                  placeholder="Old Password"
                  id="old_password"
                  type="password"
                  value={data?.old_password}
                  style={{
                    width: isMobile ? "100%" : "85%",
                    height: "42px",
                    borderRadius: "10px",
                    float: "right",
                  }}
                  ref={confirmPasswordRef}
                />
              </div>
              <div className="form-item-account">
                <label>New Password</label>{" "}
                <Input.Password
                  placeholder="New Password"
                  id="new_password"
                  type="password"
                  value={data?.new_password}
                  style={{
                    width: isMobile ? "100%" : "85%",
                    height: "42px",
                    borderRadius: "10px",
                    float: "right",
                  }}
                  ref={newPasswordRef}
                />
              </div>

              <div className="form-item-account">
                <label>Re-enter New Password</label>{" "}
                <Input.Password
                  placeholder="Re-enter Password"
                  id="confirm_password"
                  type="password"
                  value={data?.confirm_password}
                  style={{
                    width: isMobile ? "100%" : "85%",
                    height: "42px",
                    borderRadius: "10px",
                    float: "right",
                  }}
                  ref={confirmNewPasswordRef}
                />
              </div>

              <div className="float-right">
                <button
                  //
                  onClick={(e) => {
                    dispatch(setLoader(true));
                    handlePassword(e);
                    setData({
                      ...data,
                      confirm_password: "",
                      new_password: "",
                      old_password: "",
                    });
                  }}
                  className="browse-image mt-[15px] md:mb-[0px] mb-[15px]  flex justify-center items-center cursor-pointer ml-auto md:mr-[64px]"
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={showImageEditModal}
        onOk={() => setShowImageEditModal(false)}
        onCancel={() => {
          setImages(null);
          setShowImageEditModal(false);
        }}
        maskClosable={true}
        width={!isMobile && 700}
      >
        <div className="image-editor-modal-content-container">
          <h4 className="heading-editor">Edit and Upload</h4>
          <AvatarEditor
            ref={editor}
            image={images}
            width={250}
            height={300}
            border={50}
            rotate={imageEditingData.rotate}
            scale={imageEditingData.scale}
            style={{
              width: "100%",
              borderRadius: "10px",
              objectFit: "cover",
            }}
            backgroundColor="#fff"
          />
          <div className="image-editor-controls">
            <button
              className="image-editor-button"
              onClick={() =>
                setImageEdtingData((prev) => ({
                  ...prev,
                  scale: prev?.scale + 0.2,
                }))
              }
            >
              <ZoomInOutlined />
            </button>
            <button
              className="image-editor-button"
              onClick={() =>
                setImageEdtingData((prev) => ({
                  ...prev,
                  scale: prev?.scale - 0.2 >= 1 ? prev?.scale - 0.2 : 1,
                }))
              }
            >
              <ZoomOutOutlined />
            </button>
          </div>
          <button
            className="image-save-button-in-editor"
            onClick={handleSubmitImage}
          >
            Save
          </button>
        </div>
      </Modal>
    </section>
  );
};
