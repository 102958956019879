import React, { useState, useEffect, useRef } from "react";
import { Stethoscope } from "../../../assets/Images";
import moment from "moment";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PhoneNumber from "../../Common/PhoneNumber";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { message } from "antd";
import { useLoadScript } from "@react-google-maps/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import TextField from "@mui/material/TextField";

const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

const PersonalInfo = ({ data, setData, name, validate, errors, setErrors }) => {
  const user = useSelector((state) => state.auth.userData);
  const [locationAvailable, setLocationAvailable] = useState(true);
  const [contextHolder] = message.useMessage();

  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();
  const [phoneNum, setphoneNum] = useState(
    user?.phone_number || data?.phone_number
  );

  console.log("===USER==", user);

  // Libraries we want to load
  const libraries = ["places"];

  const [location, setLocation] = useState(
    user?.location?.address || data?.location
  );
  const [location_label, setLocation_label] = useState(
    data?.location?.address || ""
  );
  const [latitude, setLatitude] = useState(
    user?.location?.coordinates?.[1] || data?.location?.coordinates?.[1] || 0
  );
  const [longitude, setLongitude] = useState(
    user?.location?.coordinates?.[0] || data?.location?.coordinates?.[0] || 0
  );

  const [dob, setDob] = useState(
    dayjs(data?.dob, "YYYY/MM/DD" || user?.dob, "YYYY/MM/DD")
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Update data when phoneNum changes
    setData((prevData) => ({
      ...prevData,
      phone_number: phoneNum,
    }));
  }, [phoneNum]);

  useEffect(() => {
    if (location_label) {
      setData((prevData) => ({
        ...prevData,
        location: {
          address: location_label || "",
          coordinates: [longitude || 0, latitude || 0],
        },
      }));
    }
  }, [location_label, longitude, latitude]);

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const handleChange = (value) => {
    setErrors({ gender: "" });
    setData({ ...data, gender: value });
  };

  const onChange = (event) => {
    const selectedDate = event.target.value;

    setData({
      ...data,
      dob: selectedDate,
    });
  };

  // Disable future dates
  const maxDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  const dateFormat = "YYYY-MM-DD";
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA07lD6jhIN2OwvpX1cXsj5NAiN3T5pDjk", // Replace with your API key
    // libraries,
  });

  const searchInputRef = useRef(null);

  const getAddressFromCoordinates = async (latitude, longitude) => {
    try {
      setIsLoading(true);
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

      const response = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              resolve(results[0]);
            } else {
              reject(new Error("No results found"));
            }
          } else {
            reject(new Error("Geocoder failed: " + status));
          }
        });
      });

      setLocation_label(response.formatted_address);
      setLocation(response.formatted_address);
      setLatitude(latitude);
      setLongitude(longitude);

      console.log("Location found:", response.formatted_address);
    } catch (error) {
      console.error("Error getting address:", error);
      setError("Failed to get address details");
    } finally {
      setIsLoading(false);
    }
  };

  const getlocationPermison = async () => {
    setError("");
    console.log("Getting location permission");

    if (!navigator.geolocation) {
      message.error("Geolocation is not supported by your browser");
      return;
    }

    try {
      setIsLoading(true);
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      });

      console.log("Position received:", position);
      await getAddressFromCoordinates(
        position.coords.latitude,
        position.coords.longitude
      );
    } catch (error) {
      console.error("Error getting location:", error);
      if (error.code === 1) {
        message.error(
          "Location permission denied. Please enable location access in your browser and system settings."
        );
      } else if (error.code === 2) {
        message.error("Location unavailable. Please try again.");
      } else {
        message.error("Failed to get your location. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (newValue) => {
    const formattedDate = newValue ? newValue.format("YYYY/MM/DD") : "";
    setDob(newValue);
    setData((prev) => ({ ...prev, dob: formattedDate }));
  };

  // Render loading and error states
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <>
      <div className="first-step "></div>
      <div className=" w-[100%] md:flex justify-between personal-bgg">
        {/* {contextHolde r} */}

        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Personal Info</h1>
            <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p>
            <div className="flex mt-[40px] border-b">
              <h6>
                {name == "urgent"
                  ? "Urgent Care"
                  : name == "video"
                  ? "Video Call"
                  : "In-Person"}
              </h6>
              <Stethoscope className=" ml-[10px]" />
            </div>
          </div>
          <div className="flex  items-end ]">
            {/* <img
              src={personalBg}
              className="md:block hidden absolute bottom-0"
            /> */}
          </div>
        </div>
        <div className="md:w-[50%] md:pr-[68px] md:pl-[0px] pr-[20px] pl-[20px]">
          <h6 className="mt-[15px] md:mt-0 text-[#002FA7]">
            Please complete the following form for{" "}
            {name === "urgent"
              ? "Urgent Care"
              : name === "video"
              ? "Video Call"
              : "In-Person"}{" "}
            consultation.
          </h6>
          <form
            onChange={({ target: { value, id } }) => {
              setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));

              setData((prevData) => ({
                ...prevData,
                ...(id && { [id]: value }),
                address: location,
              }));
            }}
          >
            <div className="my-[15px] w-[100%] mt-[10px]">
              <Input
                placeholder="First name"
                id="first_name"
                value={data?.first_name}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.first_name && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.first_name}
                </p>
              )}
              <Input
                placeholder="Last name"
                id="last_name"
                value={data?.last_name}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.last_name && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.last_name}
                </p>
              )}
              <Input
                placeholder="Email Address"
                id="email"
                value={data?.email}
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.email}
                </p>
              )}

              {/* <>
                {user ? (
                  <>
                    <input
                      type="date"
                      onChange={onChange}
                      defaultValue={
                        user?.dob ? user?.dob.split("T")[0] : maxDate
                      }
                      max={maxDate} // Disable future dates
                      id="dob"
                      style={{
                        color: data?.dob ? "#000" : "#999",
                        marginTop: "8px",
                        width: "100%",
                        height: "42px",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid #d9d9d9",
                        WebkitAppearance: "none",
                      }}
                      placeholder="Date of birth"
                    />

                    {errors?.dob && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "2px 0px 0px 2px",
                        }}
                      >
                        {errors?.dob}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="date"
                      onChange={onChange}
                      max={maxDate} // Disable future dates
                      id="dob"
                      style={{
                        color: data?.dob ? "#000" : "#999",
                        marginTop: "8px",
                        width: "100%",
                        height: "42px",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid #d9d9d9",
                        WebkitAppearance: "none",
                      }}
                      placeholder="Date of birth"
                    />

                    {errors?.dob && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "2px 0px 0px 2px",
                        }}
                      >
                        {errors?.dob}
                      </p>
                    )}
                  </>
                )}
              </> */}
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  // adapterLocale={"en-gb"}
                >
                  <DatePicker
                    format="YYYY/MM/DD"
                    inputFormat="YYYY/MM/DD"
                    mask="____/__/__"
                    label="Date of Birth"
                    value={dob}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "100%",
                          marginTop: "15px",
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            borderColor: "#bfbfbf",
                            height: "42px",
                            backgroundColor: "white",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(59 130 246 / 0.3)",
                            },
                          },
                          "& .MuiInputBase-input": {
                            height: "42px",
                            padding: "0 14px",
                            display: "flex",
                            alignItems: "center",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                            color: "#bfbfbf",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors?.dob && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "11px",
                      margin: "2px 0px 0px 2px",
                    }}
                  >
                    {errors?.dob}
                  </p>
                )}
              </>

              <Select
                placeholder="Select Gender"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
                allowClear
                onChange={handleChange}
                defaultValue={data?.gender == "" ? undefined : data?.gender}
                options={[
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                ]}
              />
              {errors?.gender && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "4px 0px 0px 2px",
                  }}
                >
                  {errors?.gender}
                </p>
              )}

              <>
                <div
                  className="bg-[#fff]"
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    height: "42px",
                    borderRadius: "10px",
                    border: "1px solid #d9d9d9",
                    display: "flex",
                  }}
                >
                  <div
                    onClick={() => {
                      getlocationPermison();
                      setshown(false);
                    }}
                    style={{
                      height: "40px",
                      borderRadius: "10px 0px 0px 10px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                      cursor: "pointer",
                      width: "100%",
                      ...(!location_label && { color: "grey" }),
                    }}
                  >
                    {location_label || "Click to get your location"}
                  </div>

                  <div
                    onClick={() => {
                      getlocationPermison();
                      setshown(false);
                    }}
                    className="bg-[#e8f4fe]  cursor-pointer w-[4rem]  rounded-r-[10px]  flex justify-center items-center"
                  >
                    <i className="fa-solid fa-location-crosshairs text-[#002fa7]   text-2xl   "></i>
                  </div>
                </div>
              </>

              <Input
                placeholder="Your reachable address in Japan"
                id="japan_email"
                defaultValue={
                  data?.japan_email && data.japan_email !== "undefined"
                    ? data?.japan_email
                    : ""
                }
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
                value={
                  data?.japan_email && data.japan_email !== "undefined"
                    ? data?.japan_email
                    : ""
                }
                autoComplete="off"
              />
              {errors?.japan_email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "5px 0px 0px 5px",
                  }}
                >
                  {errors?.japan_email}
                </p>
              )}
              <Input
                placeholder="Phone Number"
                onChange={(e) => setphoneNum(e.target.value)}
                value={phoneNum}
                type="number"
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {/* <PhoneNumber setphoneNum={setphoneNum} errors={errors} /> */}
              {errors?.phone_number && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "19px 0px 0px 5px",
                  }}
                >
                  {errors?.phone_number}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
