import React, { useEffect, useState } from "react";
import ConsultationNav from "../Patient/Nav/ConsultationNav";
import "./DetailsPage.css";
import UserProfile from "../../assets/Images/userProfile.svg";
import { UserDp, VedioIcon } from "../../assets/Images/index";
import download from "../../assets/Images/download.png";
import DetailsOverview from "./DetailsOverview";
import PersonalInformation from "./PersonalInformation";
import PrescriptionDetails from "./PrescriptionDetails";
import Pharmacy from "./Pharmacy";
import MedHistory from "./MedHistory";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { ApiCall } from "../../Services/apis";
import Swal from "sweetalert2";
import DoctorDetailsInformation from "./DoctorDetailsInformation";
import ReView from "./ReView";
import NotesPage from "./NotesPage";
import HistoryPagess from "./HistoryPagess";
import condisionIcon from "../../assets/iconCondition.svg";
import videoIconmain from "../../assets/vicon.svg";
import schedulVideo from "../../assets/scheduleIcon.svg";
import messageIcon from "../../assets/messageIcon.svg";
import { differenceInMinutes, formatDistance } from "date-fns";
import PrimaryButton from "../UI/PrimaryButton";
import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Details = ({ Details }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showOverview, setShowOverview] = useState(true);
  const token = useSelector((state) => state.auth.userToken);
  const user = useSelector((state) => state.auth.userData);
  const isLoading = useSelector((state) => state.general.loader);

  const [bookingDetail, setBookingDetail] = useState();
  const { id } = useParams();
  const [showBooking, setShowBooking] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showPersonalinfo, setShowPersonalInfo] = useState(true);
  const [showMedicalHistory, setShowMedicalHistory] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [showPharmacy, setShowPharmacy] = useState(false);
  const [showDoctorInfo, setShowDoctorInfo] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [bookingType, setBookingType] = useState(false);

  // console.log("bookingType",bookingType)

  const handleOverview = () => {
    if (
      showBooking === true ||
      showReview === true ||
      showNotes === true ||
      showHistory === true
    ) {
      setShowBooking(false);
      setShowReview(false);
      setShowNotes(false);
      setShowHistory(false);
    }
    setShowOverview(true);
  };
  const handleBooking = () => {
    if (
      showOverview === true ||
      showReview === true ||
      showNotes === true ||
      showHistory === true
    ) {
      setShowOverview(false);
      setShowReview(false);
      setShowNotes(false);
      setShowHistory(false);
    }
    setShowBooking(true);
  };
  const handleReview = () => {
    if (
      showBooking === true ||
      showOverview === true ||
      showNotes === true ||
      showHistory === true
    ) {
      setShowOverview(false);
      setShowBooking(false);
      setShowNotes(false);
      setShowHistory(false);
    }
    getConsultatioDetail(false);
    setShowReview(true);
  };
  const handleNotes = () => {
    if (
      showOverview === true ||
      showBooking === true ||
      showHistory === true ||
      showReview === true
    ) {
      setShowOverview(false);
      setShowBooking(false);
      setShowReview(false);
      setShowHistory(false);
    }
    setShowNotes(true);
  };

  const handleHistory = () => {
    if (
      showOverview === true ||
      showBooking === true ||
      showReview === true ||
      showNotes === true
    ) {
      setShowOverview(false);
      setShowBooking(false);
      setShowReview(false);
      setShowHistory(false);
      setShowNotes(false);
    }
    setShowHistory(true);
  };

  const handlePersonalInfo = () => {
    if (
      showPharmacy === true ||
      showMedicalHistory === true ||
      showPrescription === true ||
      showDoctorInfo === true
    ) {
      setShowMedicalHistory(false);
      setShowPharmacy(false);
      setShowPrescription(false);
      setShowDoctorInfo(false);
    }
    setShowPersonalInfo(true);
  };
  const handleDoctorinfo = () => {
    if (
      showPersonalinfo === true ||
      showPrescription === true ||
      showPharmacy === true ||
      showMedicalHistory === true
    ) {
      setShowPersonalInfo(false);
      setShowPharmacy(false);
      setShowPrescription(false);
      setShowMedicalHistory(false);
    }
    setShowDoctorInfo(true);
  };

  const handleMedicalHistory = () => {
    if (
      showPersonalinfo === true ||
      showPrescription === true ||
      showPharmacy === true ||
      showDoctorInfo === true
    ) {
      setShowPersonalInfo(false);
      setShowPharmacy(false);
      setShowPrescription(false);
      setShowDoctorInfo(false);
    }
    setShowMedicalHistory(true);
  };
  const handlePrescription = () => {
    if (
      showPersonalinfo === true ||
      showMedicalHistory === true ||
      showPharmacy === true ||
      showDoctorInfo === true
    ) {
      setShowPersonalInfo(false);
      setShowMedicalHistory(false);
      setShowPharmacy(false);
      setShowDoctorInfo(false);
    }
    setShowPrescription(!showPrescription);
    getConsultatioDetail(false);
  };

  const handlePharmacy = () => {
    if (
      showPersonalinfo === true ||
      showMedicalHistory === true ||
      showPrescription === true ||
      showDoctorInfo === true
    ) {
      setShowPersonalInfo(false);
      setShowMedicalHistory(false);
      setShowPrescription(false);
      setShowDoctorInfo(false);
    }
    setShowPharmacy(!showPharmacy);
  };
  const getConsultatioDetail = async (loader = true) => {
    try {
      if (loader) {
        dispatch(setLoader(true));
      }
      const res = await ApiCall({
        route: `booking/get_booking_detail/${id ? id : Details?._id}`,
        verb: "get",
        token: token,
      });
      if (res?.status == "200") {
        console.log("response", res?.response);
        setBookingDetail(res?.response?.booking_detail);
        dispatch(setLoader(false));
      } else {
        console.log("error", res.response);
        Swal.fire(res.response.message, "", "error");
        dispatch(setLoader(false));
      }
    } catch (e) {
      dispatch(setLoader(false));

      console.log("saga login error -- ", e.toString());
    }
  };

  useEffect(() => {
    dispatch(setLoader(true));
    getConsultatioDetail();
  }, []);

  const handleJoinMeeting = (item, userId) => {
    const date = new Date(item?.schedule?.start_time);
    const currentTime = new Date();
    var timeDifference = differenceInMinutes(date, currentTime);

    const timeDifferenceFormat = formatDistance(date, currentTime, {
      includeSeconds: false,
    });

    if (
      timeDifference <= 5 &&
      timeDifference > -25 &&
      item?.payment_status == true
    ) {
      navigate(`/video-chat?roomId=${item._id}&&name=${userId}`);
    } else if (timeDifference < -90) {
      Swal.fire({
        titleText: `Meeting time is Over`,
        icon: "error",
      });
      return;
    } else {
      Swal.fire({
        title: `You can't join meeting for now!`,
        text: `You can only join meeting 5 minutes before the starting time. You can join after ${timeDifferenceFormat}. 
            `,
        icon: "error",
      });
    }
  };
  const handleJoinUrgentCare = (roomId, userId, contactType) => {
    // console.log("room id is this", roomId);
    navigate(
      `/video-chat?roomId=${roomId}&&name=${userId}&&type=urgent&&contactType=${contactType}`
    );
  };

  return (
    <>
      {Details ? null : (
        <header>
          <ConsultationNav />
        </header>
      )}
      {bookingDetail ? (
        <>
          <section>
            <div className={Details ? `detail-container` : `details-container`}>
              <div className="description-section">
                {/* left Side */}
                <div className="desc-leftside-sec">
                  <div className="flex items-center ml-[0.5%]">
                    {/* <h5>
                {bookingDetail?.booking_type} <small>with</small>
              </h5> */}

                    <img
                      src={
                        bookingDetail?.doctor_id?.profile_image || UserProfile
                      }
                      alt="user image"
                      height="29px"
                      width="29px"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                      className="cursor-pointer  "
                    />
                    <div>
                      {!bookingDetail?.doctor_id?.user_id ? (
                        <p className="pl-[4%] text-[#0A0A0A] text-[20px] font-bold w-max">
                          ACO Clinic
                        </p>
                      ) : (
                        <p className="pl-[4%] text-[#0A0A0A] text-[20px] font-bold w-max">
                          {bookingDetail?.doctor_id?.first_name +
                            " " +
                            bookingDetail?.doctor_id?.last_name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="desc-rightside-sec">
                  {(bookingDetail?.booking_status == "upcoming" ||
                    bookingDetail?.booking_status == "none" ||
                    bookingDetail?.booking_status == "on_going") && (
                    <div
                      onClick={() =>
                        bookingDetail?.booking_type == "video_call"
                          ? handleJoinMeeting(bookingDetail, user?.user_id)
                          : handleJoinUrgentCare(
                              bookingDetail?._id,
                              user?.user_id,
                              "urgent"
                            )
                      }
                    >
                      {bookingDetail?.booking_type === "visit" ||
                      location.pathname == "/video-chat"
                        ? null
                        : bookingDetail?.payment_status == true && (
                            <PrimaryButton>Join Meeting</PrimaryButton>
                          )}
                    </div>
                  )}
                  {bookingDetail?.booking_status == "re_assign" &&
                  bookingDetail?.booking_type == "video_call"
                    ? ""
                    : !location.pathname?.startsWith("/video-chat") && (
                        <div
                          className="message-sec cursor-pointer"
                          onClick={() => {
                            console.log(bookingDetail, "bokking details");
                            navigate(
                              `/messages?selectedChatId=${bookingDetail?.chat_id}`
                            );
                          }}
                        >
                          <img
                            src={messageIcon}
                            alt="MessageIcon Miss"
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                      )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row my-[13px] ml-[0.5%] gap-[8px] items-start md:items-center">
                {/* left Side */}
                <div className="flex gap-[4px] items-center bg-[#E5EAF6] rounded-[18px] px-[12px] py-[6px]">
                  {bookingDetail?.booking_type == "video_call" ? (
                    <>
                      <img src={videoIconmain} alt="videoIconmain Miss" />

                      <h5 className="mb-[0px]">
                        {bookingDetail?.booking_type ? (
                          <p className="text-[#0B32A3] text-[13px] font-normal mb-[0px]">
                            Video
                          </p>
                        ) : (
                          ""
                        )}
                      </h5>
                    </>
                  ) : bookingDetail?.booking_type == "visit" ? (
                    <>
                      <img src={condisionIcon} alt="iconMissing" />

                      <h5 className="text-[#0B32A3] text-[13px] font-normal">
                        {bookingDetail?.booking_type}
                      </h5>
                    </>
                  ) : bookingDetail?.booking_type == "urgent_care" ? (
                    <>
                      <svg
                        width="14"
                        height="9"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.25 4.125H16.5V2.0625C16.5 0.925117 15.5762 0 14.4375 0H7.5625C6.42383 0 5.5 0.925117 5.5 2.0625V4.125H2.75C1.23105 4.125 0 5.3582 0 6.875V17.875C0 19.3939 1.23105 20.625 2.75 20.625H19.25C20.7689 20.625 22 19.3939 22 17.875V6.875C22 5.3582 20.7668 4.125 19.25 4.125ZM6.875 2.0625C6.875 1.68309 7.18437 1.375 7.5625 1.375H14.4375C14.8156 1.375 15.125 1.68309 15.125 2.0625V4.125H6.875V2.0625ZM20.625 17.875C20.625 18.633 20.008 19.25 19.25 19.25H2.75C1.99203 19.25 1.375 18.633 1.375 17.875V6.875C1.375 6.11703 1.99203 5.5 2.75 5.5H19.25C20.008 5.5 20.625 6.11703 20.625 6.875V17.875ZM14.4375 11H12.375V8.9375C12.375 8.55937 12.0656 8.25 11.6875 8.25H10.3125C9.93437 8.25 9.625 8.55937 9.625 8.9375V11H7.5625C7.18437 11 6.875 11.3094 6.875 11.6875V13.0625C6.875 13.4406 7.18437 13.75 7.5625 13.75H9.625V15.8125C9.625 16.1906 9.93437 16.5 10.3125 16.5H11.6875C12.0672 16.5 12.375 16.1922 12.375 15.8125V13.75H14.4375C14.8172 13.75 15.125 13.4422 15.125 13.0625V11.6875C15.125 11.3094 14.8156 11 14.4375 11Z"
                          fill="#002FA7"
                        />
                      </svg>

                      <h5 className="text-[#0B32A3] text-[13px] font-normal ]">
                        {bookingDetail?.booking_type}
                      </h5>
                    </>
                  ) : null}
                </div>

                <div>
                  <div className="condition-sec">
                    <img src={condisionIcon} alt="conditionIconMissing" />
                    <h5 className="mb-[0px]">
                      {bookingDetail?.category_id?.category_name}
                    </h5>
                  </div>
                </div>
                {/* right side */}
              </div>
              <div className="tabs-section-menu ">
                <p
                  onClick={handleOverview}
                  className={`${showOverview ? "activeTab" : null}`}
                >
                  Overview
                </p>
                <p
                  onClick={handleBooking}
                  className={`${showBooking ? "activeTab" : null}`}
                >
                  Consultation Details
                </p>

                {bookingType ? null : (
                  <p
                    onClick={handleReview}
                    className={`${showReview ? "activeTab" : null}`}
                  >
                    Feedback
                  </p>
                )}

                {/* <p
              onClick={handleNotes}
              className={`${showNotes ? "activeTab" : null}`}
            >
              Notes
            </p> */}
              </div>
            </div>
          </section>
          <section
            className={
              showBooking === true ? "lower-booking-sectionss" : "lower-section"
            }
          >
            {showOverview && <DetailsOverview bookingDetail={bookingDetail} />}
            {showNotes && <NotesPage bookingDetail={bookingDetail} />}
            {showHistory && <HistoryPagess bookingDetail={bookingDetail} />}

            {/* Booking Navigation Bar */}

            {showReview && <ReView bookingDetail={bookingDetail} />}

            {showBooking && (
              <div className="booking-card">
                <h3 className="headings px-3">Consultation Details:</h3>
                <div className="booking-nav-bar px-3">
                  <div
                    className={
                      showPersonalinfo
                        ? "booking-nav-content-active"
                        : "booking-nav-content"
                    }
                  >
                    <h3 onClick={handlePersonalInfo}>Personal Info</h3>
                  </div>
                  {bookingDetail?.booking_type === "urgent_care" ? null : (
                    <div
                      className={
                        showDoctorInfo
                          ? "booking-nav-content-active"
                          : "booking-nav-content"
                      }
                    >
                      <h3 onClick={handleDoctorinfo}>Doctor's Info</h3>
                    </div>
                  )}
                  <div
                    className={
                      showMedicalHistory
                        ? "booking-nav-content-active"
                        : "booking-nav-content"
                    }
                  >
                    <h3 onClick={handleMedicalHistory}>Medical History</h3>
                  </div>

                  <div
                    className={
                      showPrescription
                        ? "booking-nav-content-active"
                        : "booking-nav-content"
                    }
                  >
                    <h3 onClick={handlePrescription}>Prescription</h3>
                  </div>

                  {/* <div
                className={
                  showPharmacy
                    ? "booking-nav-content-active"
                    : "booking-nav-content"
                }
              >
                <h3 onClick={handlePharmacy}>Pharmacy</h3>
              </div> */}
                </div>

                {showPersonalinfo && (
                  <PersonalInformation bookingDetail={bookingDetail} />
                )}
                {showPrescription && (
                  <PrescriptionDetails bookingDetail={bookingDetail} />
                )}
                {showPharmacy && <Pharmacy bookingDetail={bookingDetail} />}
                {showMedicalHistory && (
                  <MedHistory bookingDetail={bookingDetail} />
                )}
                {showDoctorInfo && (
                  <DoctorDetailsInformation bookingDetail={bookingDetail} />
                )}
              </div>
            )}
          </section>
        </>
      ) : (
        !isLoading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
            />
          </div>
        )
      )}
    </>
  );
};

export default Details;
