import React, { useState, useEffect } from "react";
import "./Login.css";
import Logo from "../../assets/Images/AmericanClinicLoginLogo.svg";
import { Input } from "antd";
import dayjs from "dayjs";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUserRequest,
  startSignUpAsGuest,
} from "../../Redux/Actions/AuthActions";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import PrimaryButton from "../../components/UI/PrimaryButton";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/zh-cn";
import TextField from "@mui/material/TextField";

const Signupp = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    dob: "",
  });
  const [value, setValue] = useState(null);
  const navigate = useNavigate();

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = handleValidation();
    console.log(errors, "erros");
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const filteredData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      dob: data.dob,
    };

    console.log("Submitted Data: ", data);

    dispatch(signUpUserRequest({ data: filteredData, navigate }));
    dispatch(setLoader(true));
    // navigate("/login");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }, []);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const handleValidation = () => {
    const validationErrors = {};

    // Check if any field is empty and save the error
    if (data.first_name.trim() === "" || !data.first_name) {
      validationErrors.first_name = "First name is required";
    }
    if (data.last_name.trim() === "" || !data.last_name) {
      validationErrors.last_name = "Last name is required";
    }
    if (
      data.email.trim() === "" ||
      !validator.isEmail(data.email) ||
      !data.email
    ) {
      validationErrors.email = "Email is required";
    }
    if (data.password.trim() === "" || !data.password) {
      validationErrors.password = "Password is required";
    } else if (data?.password.length < 5) {
      validationErrors.password = "Password must be 5 characters long";
    }

    if (!data.dob) {
      validationErrors.dob = "Date of birth is required";
    } else {
      const dob = dayjs(data.dob, "YYYY/MM/DD");
      const currentDate = dayjs();
      const userAge = currentDate.diff(dob, "year");

      if (userAge < 15) {
        validationErrors.dob = "Patient must be at least 15 years old.";
      }
    }

    // Update the errors state object
    setErrors(validationErrors);
    return validationErrors;
  };

  const continueAsGuestHandler = () => {
    dispatch(startSignUpAsGuest({ status: true }));
  };

  return (
    <section className="h-[100vh] ">
      <div
        className="h-[15vh] back-color"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/">
          <img src={Logo}></img>
        </Link>
      </div>
      <div
        className=" w-[100%] "
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <center>
          <h2 className="login-heading mt-[50px]">Sign up</h2>
        </center>
        <form
          onChange={({ target: { value, id } }) => {
            setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));

            setData({
              ...data,
              ...(id && { [id]: value }),
            });
          }}
          autoComplete="off"
        >
          <div className="w-[363px] ">
            <Input
              required
              placeholder="First name"
              id="first_name"
              className="inputt"
              autoComplete="off"
              style={{
                marginTop: "15px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.first_name}
            </span>

            <div className="">
              <Input
                placeholder="Last name"
                id="last_name"
                className="inputt"
                required
                autoComplete="off"
                style={{
                  marginTop: "15px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              <span
                style={{
                  fontWeight: "light",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {errors.last_name}
              </span>
            </div>

            {/* <input
              type="date"
              onChange={onDateChange}
              value={data.dob}
              className="date-picker"
              id="dob"
              autoComplete="off"
              placeholder="dd/mm/yyyy" // Custom placeholder text
              style={{
                color: data.dob ? "#000" : "#999",
                marginTop: "8px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
                border: "1px solid #d9d9d9",
                padding: "4px 11px",
              }}
            /> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY/MM/DD"
                inputFormat="YYYY/MM/DD"
                mask="____/__/__"
                label="Date of Birth"
                value={data.dob ? dayjs(data.dob, "YYYY/MM/DD") : null}
                onChange={(newValue) => {
                  const formattedDate = newValue
                    ? newValue.format("YYYY/MM/DD")
                    : "";
                  setData((prevData) => ({
                    ...Object.fromEntries(
                      Object.entries(prevData).filter(
                        ([key]) => !key.startsWith(":")
                      )
                    ),
                    dob: formattedDate,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "100%",
                      marginTop: "15px",
                      "& .MuiInputBase-root": {
                        borderRadius: "10px",
                        borderColor: "#bfbfbf",
                        height: "42px",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)", // Keep default border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(59 130 246 / 0.3)",
                        },
                      },
                      "& .MuiInputBase-input": {
                        height: "42px",
                        padding: "0 14px",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "0.8rem",
                        top: "-5px",
                        color: "#bfbfbf",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.dob}
            </span>
            <Input
              placeholder="Email Address"
              id="email"
              className="inputt"
              required
              autoComplete="off"
              // onChange={(e) => emailvalidation(e.target.value)}
              style={{
                marginTop: "15px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.email}
            </span>
            <Input.Password
              placeholder="Password"
              id="password"
              className="inputt"
              type="password"
              required
              autoComplete="off"
              style={{
                marginTop: "15px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.password}
            </span>
            <div className="w-[100%] mt-[10px]">
              <div className="flex justify-between">
                <Link
                  to="/prescription/urgent"
                  onClick={continueAsGuestHandler}
                >
                  <button className="guest-btn" type="button">
                    Continue as guest
                  </button>
                </Link>
                {/* <Link to="/disclaimer"> */}
                <PrimaryButton className="signup-btn " onClick={handleSubmit}>
                  Sign Up
                </PrimaryButton>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </form>

        <div className="mt-[40px] mb-[20px]">
          <center>
            <p className="signup">
              Already have an account?
              <span className="text-[#002FA7] ml-[5px] ">
                <Link to="/login">Login</Link>
              </span>
            </p>
          </center>
        </div>
      </div>
    </section>
  );
};

export default Signupp;
