import React, { useEffect, useState } from "react";
import "./ChatUsers.scss";
import searchIcon from "../../assets/Images/search-msg.png";
import userIcon from "../../assets/Images/user.png";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function ChatUsers({
  selectedChat,
  setSelectedChat,
  setShowMessagesBox,
  allChats,
  setNotifications,
  notifications,
  getChatDetails,
  getNotifications,
  markAllAsRead,
  messageLimit,
  setMessageLimiter,
  messageLoad,
  totalMessage,
}) {
  const isDesktop = useMediaQuery({ query: "(min-width: 760px)" });
  const onlineUsers = useSelector(
    (state) => state.onlineUsers?.onlineUsers || []
  );
  const appUser = useSelector((state) => state.auth.userData);
  const [allUsers, setAllUsers] = useState(allChats || []);
  const [allUsersFiltered, setAllUsersFiltered] = useState(allChats || []);

  const onSelectChat = async (chat) => {
    setSelectedChat(chat);
    setShowMessagesBox(true);

    //? ///////////////////////////////////////////////////////
    //? NEW CODE
    // Clear notifications when chat is selected on mobile
    if (!isDesktop && notificationCountRenderer(chat._id) > 0) {
      await markAllAsRead();
    }
    //? ///////////////////////////////////////////////////////
  };

  useEffect(() => {
    if (allChats?.length > 0 && isDesktop) {
      if (!selectedChat) {
        setSelectedChat(allChats[0]);
      } else {
        const found = allChats.find((e) => e._id === selectedChat?._id);
        setSelectedChat(found || allChats[0]);
      }
    }
    setAllUsers(allChats);
    setAllUsersFiltered(allChats);
  }, [allChats]);

  const notificationCountRenderer = (chatId) => {
    let count = 0;
    notifications
      .filter((e) => e.chatId === chatId)
      .forEach((notification) => {
        if (!notification?.isRead) count++;
      });
    return count;
  };

  //! ///////////////////////////////////////////////////////
  //! OLD CODE
  // useEffect(() => {
  //   if (selectedChat?._id && notificationCountRenderer(selectedChat?._id) > 0) {
  //     markAllAsRead();
  //   }
  // }, [notifications, selectedChat?._id]);
  //! ///////////////////////////////////////////////////////

  //? ///////////////////////////////////////////////////////
  //? NEW CODE
  // Keep the existing effect for desktop view
  useEffect(() => {
    if (
      isDesktop &&
      selectedChat?._id &&
      notificationCountRenderer(selectedChat?._id) > 0
    ) {
      markAllAsRead();
    }
  }, [notifications, selectedChat?._id, isDesktop]);
  //? ///////////////////////////////////////////////////////

  const onSearchChat = (e) => {
    const value = e.target.value;
    const filtered = allUsers.filter((user) => {
      return `${user?.doctor?.first_name} ${user?.doctor?.last_name}`
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    setAllUsersFiltered(filtered);
  };

  return (
    <div className="all-chat-users-main-container">
      <div className="chat-users-search-filter-container">
        <div className="search-input-container">
          <img src={searchIcon} alt="search-icon" />
          <input
            type="text"
            placeholder="Search a user..."
            onChange={onSearchChat}
          />
        </div>
      </div>
      <div className="chat-users-container">
        {allUsersFiltered?.length > 0 ? (
          allUsersFiltered?.map((user, index) => {
            return (
              <div
                className={`single-chat-user-container ${
                  selectedChat?._id === user?._id
                    ? "single-chat-user-active"
                    : ""
                }  `}
                onClick={() => onSelectChat(user)}
              >
                <div className="single-chat-user-left">
                  <img
                    src={user?.doctor?.profile_image || userIcon}
                    alt={user?.doctor?.profile_image}
                  />
                  <div
                    className={`chat-user-online-status-circle ${
                      onlineUsers.includes(user?.doctor?.user_id)
                        ? "online-status-true"
                        : ""
                    }`}
                  ></div>
                </div>
                <div className="single-chat-user-right">
                  <h4>{user?.booking[0]?.category_id?.category_name}</h4>
                  <h5>
                    {`${
                      user?.doctor?.first_name
                        ? "Dr." +
                          " " +
                          user?.doctor?.first_name +
                          " " +
                          user?.doctor?.last_name
                        : "American Clinic"
                    }`}
                  </h5>
                  <div className="side-by-side-elements">
                    <div className="last-message-container">
                      <span className="last-message-text-span">
                        {user?.last_message?.sender === appUser?.user_id
                          ? "You:  "
                          : `${
                              user?.doctor?.first_name ||
                              user?.last_message?.sender_role
                            }:  `}

                        {/* {user?.last_message?.file?.url && <DetailImg />} */}
                        <i>
                          {" "}
                          {user?.last_message?.message ||
                            (user?.last_message?.file?.url && "Attachment") ||
                            "Tap to message."}
                        </i>
                      </span>
                    </div>
                    {notificationCountRenderer(user?._id) > 0 ? (
                      <span className="unread-status">
                        Unread ({notificationCountRenderer(user?._id)})
                      </span>
                    ) : (
                      <span>
                        {user?.last_message?.date
                          ? new Date(
                              user?.last_message?.date
                            ).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : null}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-chat-users-found">
            <h3>No users found.</h3>
          </div>
        )}

        {allUsersFiltered?.length > 0 && (
          <>
            {messageLoad ? (
              <p className="load">Loading ...</p>
            ) : totalMessage == allUsers.length ? (
              <p className="text-dark text-center m-auto">No More</p>
            ) : (
              <p
                onClick={() => setMessageLimiter(messageLimit + 1)}
                className="load"
              >
                Load More
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ChatUsers;
