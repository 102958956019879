import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import ReactStars from "react-rating-stars-component";
import { ApiCall } from "../../Services/apis";
import Reviews from "../Patient/LandingPage/Reviews/Reviews";
import Review from "../Patient/Review/Review";
import "./DetailsPage.css";
import { Rating } from "@mui/material";

const ReView = ({ bookingDetail }) => {
  console.log("Booking Details in Review", bookingDetail);
  const token = useSelector((state) => state.auth.userToken);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const id = bookingDetail?._id;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstExample = {
    size: 35,
    count: 5,
    color: "black",
    activeColor: "#002fa7",
    value: 0,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      setRating(newValue);
    },
  };
  const data = {
    comment,
    rating,
  };

  const handleSubmit = async (e) => {
    dispatch(setLoader(true));
    e.preventDefault();
    try {
      const res = await ApiCall({
        route: `review/add_review/${id}`,
        verb: "post",
        token: token,
        params: data,
      });
      //   userBook();
      console.log("review", res);
      if (res?.status == 200) {
        dispatch(setLoader(false));

        Swal.fire({
          position: "success",
          icon: "success",
          title: "Review sent ",
          showConfirmButton: false,
          timer: 2000,
        });

        navigate("/");
      } else {
        dispatch(setLoader(false));

        Swal.fire({
          position: "center",
          icon: "error",
          title: "Review not added",
          text: "Stars and review cannot be empty",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (e) {
      dispatch(setLoader(false));

      Swal.fire("Review Can't submit right now", "", "error");
    }
  };

  const currentTime = new Date();
  const startTime = new Date(bookingDetail?.schedule?.start_time);

  return (
    <>
      {bookingDetail?.booking_status == "re_assign" &&
      bookingDetail?.booking_type == "video_call" ? (
        // start
        <div className="booking-card">
          <div className="relative container w-full  mx-auto review-container ">
            <div className="border-0 py-[40px] rounded-lg  relative flex flex-col md:w-[50%] w-[90%] mx-auto bg-white outline-none focus:outline-none">
              <div className="rounded-t mb-[20px]">
                <h3 className="text-2xl text-gray-700 mb-4 text-center font-semibold">
                  Cancellation Reason
                </h3>
                <p
                  // for="exampleFormControlTextarea1"
                  className=" text-sm text-center text-red-700 font-medium"
                >
                  {bookingDetail?.cancelled_by_doctor[0].cancellation_reason}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : // end
      bookingDetail?.review == null ? (
        currentTime < startTime ? (
          <div className="booking-card">
            <div className="relative container w-full mx-auto review-container">
              <div className="border-0 py-[40px] rounded-lg relative flex flex-col md:w-[50%] w-[90%] mx-auto bg-white outline-none focus:outline-none">
                <div className="rounded-t mb-[20px] text-center">
                  <h3 className="text-2xl text-gray-500 font-semibold">
                    The session hasn't started yet. Please check back later.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="booking-card">
            <div className="relative container w-full  mx-auto review-container ">
              <div className="border-0 py-[40px] rounded-lg  relative flex flex-col md:w-[50%] w-[90%] mx-auto bg-white outline-none focus:outline-none">
                <div className="rounded-t mb-[20px]">
                  <h3 className="text-2xl text-gray-700 text-center font-semibold">
                    Rate your experience.
                  </h3>
                </div>
                <div className="relative  w-[90%] mx-auto  flex-auto ">
                  <div className="relative md:w-[70%] mx-auto flex-auto mb-[20px]">
                    <h3 className="text-sm text-gray-700  font-bold">
                      Your rating
                    </h3>
                    <ReactStars {...firstExample} />
                  </div>
                  <div className="relative md:w-[70%] mx-auto flex-auto">
                    <div class="mb-3 w-full">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label text-sm text-gray-700  font-bold"
                      >
                        Your experience
                      </label>
                      <textarea
                        className="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-sm
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            "
                        id="comment"
                        onChange={(event) => setComment(event.target.value)}
                        rows="3"
                        placeholder="Enter your review here"
                      ></textarea>
                    </div>
                  </div>
                </div>
                {bookingDetail?.booking_type !== "visit" ? (
                  <div className="flex items-center justify-center py-2     ">
                    {bookingDetail?.consultation_status === "under_review" &&
                    !window.location.pathname.includes("video-chat") ? (
                      <button
                        className="bg-[#002fa7] text-white font-bold uppercase text-sm px-7 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Submit Review
                      </button>
                    ) : (
                      <button
                        disabled
                        className="cursor-not-allowed bg-[#002fa7] text-white font-bold uppercase text-sm px-7 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        // onClick={(e) => {
                        //   handleSubmit(e);
                        // }}
                      >
                        Submit Review
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-2     ">
                    <button
                      className="bg-[#002fa7] text-white font-bold uppercase text-sm px-7 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Submit Review
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* {
    bookingDetail.map((data)=>(
    <div className='container-for-Review'>
      <h5>Rating:</h5>
      <p>statrs go here</p>
    </div>
    ))
} */}
          </div>
        )
      ) : (
        <div className="review-card">
          <div>
            <h3 className="headings">What you have said about Doctor.</h3>

            <div className="flex flex-col">
              <div className="bottom-review-card">
                {/* <div className='bottom-review-card'>

                </div> */}
                {bookingDetail?.review?.comment == null ? (
                  <>
                    <p className="pb-[10px] font-semibold">No reviews yet!</p>
                    <span className="request-button">
                      <p>Rate and Review </p>
                    </span>
                  </>
                ) : (
                  <>
                    <Rating value={bookingDetail?.review?.rating} readOnly />
                    <h4 className="mt-[10px] mb-[10px] text-[14px]">
                      {bookingDetail?.review?.comment}
                    </h4>
                    <p className="pt-[5px]">
                      <span className="font-bold text-orange-600 pt-[10px] pr-[10px] ">
                        To:
                      </span>
                      {console.log(bookingDetail)}
                      {bookingDetail?.doctor_id?.first_name} {""}{" "}
                      {bookingDetail?.doctor_id?.last_name}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReView;
