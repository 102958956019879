import React from "react";
import "./DetailsPage.css";
import UserProfile from "../../assets/Images/userProfile.svg";

const PersonalInformation = ({ bookingDetail }) => {
  return (
    <div className="p-4">
      <div className="flex flex-col min-[1150px]:flex-row gap-6">
        <div className="w-32 h-32 flex-shrink-0 self-center min-[1150px]:self-start">
          <img
            src={
              bookingDetail?.personal_info?.user_id?.profile_image ||
              UserProfile
            }
            className="w-full h-full rounded-full object-cover"
            alt="Profile"
          />
        </div>

        {/* Content Container */}
        <div className="flex flex-col min-[1150px]:flex-row gap-6">
          {/* First Column */}
          <div className="space-y-4 flex-1">
            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Full Name:
              </span>
              <span className="text-[#828282] break-words">
                {bookingDetail?.personal_info?.user_id?.first_name}{" "}
                {bookingDetail?.personal_info?.user_id?.last_name}
              </span>
            </div>

            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Email Address:
              </span>
              <span className="text-[#828282] break-words w-full overflow-hidden">
                {bookingDetail?.personal_info?.user_id?.email || "----"}
              </span>
            </div>

            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Gender:
              </span>
              <span className="text-[#828282]">
                {bookingDetail?.personal_info?.gender || "----"}
              </span>
            </div>

            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Geo Location:
              </span>
              <span className="text-[#828282] break-words">
                {bookingDetail?.personal_info?.location?.address || "----"}
              </span>
            </div>

            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Reachable Address:
              </span>
              <span className="text-[#828282] break-words">
                {bookingDetail?.personal_info?.japan_email || "----"}
              </span>
            </div>
          </div>

          {/* Second Column */}
          <div className="space-y-4 min-[1150px]:min-w-[200px]">
            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Phone Number:
              </span>
              <span className="text-[#828282]">
                {bookingDetail?.personal_info?.phone_number || "----"}
              </span>
            </div>
            <div className="flex items-start space-x-2">
              <span className="text-[#0A0A0A] min-w-32 font-[Adamina] font-bold whitespace-nowrap">
                Date of birth:
              </span>
              <span className="text-[#828282]">
                {bookingDetail?.personal_info?.user_id?.dob || "----"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
